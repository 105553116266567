function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

export function transformText(text: string) {
  return text
    .split("_")
    .map((word) => capitalizeFirstLetter(word))
    .join(" ")
}
