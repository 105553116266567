import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit"
import { authApi } from "./api/authApi"
import { techniciansApi } from "./api/techniciansApi"
import { ordersApi } from "./api/ordersApi"
import { widgetsApi } from "./api/widgetsApi"
import { clientApi } from "./api/clientApi"
import { contractorsApi } from "./api/contractorsApi"
import { dictionaryApi } from "./api/dictionaryApi"
import { profileApi } from "./api/profileApi"
import { alertsApi } from "./api/alertsApi"
import { devicesApi } from "./api/devicesApi"
import { warehousesApi } from "./api/warehousesApi"
import { warehouseManagerApi } from "./api/warehouseManagerApi"
import { adminApi } from "./api/adminApi"
import modalsReducer from "./modalSlise"
import tempDataReducer from "./tempDataSlise"
import userReducer from "./userSlise"
import { vendorApi } from "./api/vendorApi"

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [techniciansApi.reducerPath]: techniciansApi.reducer,
    [ordersApi.reducerPath]: ordersApi.reducer,
    [widgetsApi.reducerPath]: widgetsApi.reducer,
    [clientApi.reducerPath]: clientApi.reducer,
    [contractorsApi.reducerPath]: contractorsApi.reducer,
    [dictionaryApi.reducerPath]: dictionaryApi.reducer,
    [profileApi.reducerPath]: profileApi.reducer,
    [alertsApi.reducerPath]: alertsApi.reducer,
    [devicesApi.reducerPath]: devicesApi.reducer,
    [warehousesApi.reducerPath]: warehousesApi.reducer,
    [warehouseManagerApi.reducerPath]: warehouseManagerApi.reducer,
    [adminApi.reducerPath]: adminApi.reducer,
    [vendorApi.reducerPath]: vendorApi.reducer,
    modals: modalsReducer,
    user: userReducer,
    tempData: tempDataReducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(authApi.middleware)
      .concat(techniciansApi.middleware)
      .concat(ordersApi.middleware)
      .concat(widgetsApi.middleware)
      .concat(clientApi.middleware)
      .concat(contractorsApi.middleware)
      .concat(profileApi.middleware)
      .concat(alertsApi.middleware)
      .concat(dictionaryApi.middleware)
      .concat(devicesApi.middleware)
      .concat(warehousesApi.middleware)
      .concat(warehouseManagerApi.middleware)
      .concat(adminApi.middleware)
      .concat(vendorApi.middleware) //
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
