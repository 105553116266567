/* eslint-disable */
import { UITypes, ApiTypes } from "../../../types"
import { convertDataToOptions } from "../../../utils/convertData"
import { useEffect, useState } from "react"
import { usePrevious } from "../../../utils/usePrevious"
import { setCurrentZip } from "../../../store/tempDataSlise"
import { useAppDispatch } from "../../../store/hooks"
import { UseLazyQuery } from "@reduxjs/toolkit/dist/query/react/buildHooks"
import styles from "./index.module.scss"
import DropdownSelect from "react-dropdown-select"
import classNames from "classnames"
import debounce from "lodash.debounce"
import "../../../assets/libs/select.css"

interface Props {
  label?: string
  placeholder?: string
  name?: string
  values?: UITypes.Option[]
  errorMessage?: string
  valueName: string
  labelName: string
  searchFieldName?: string
  className?: string
  queryParams?: UITypes.Params
  onChange?: (value: UITypes.Option[]) => void
  useLazyQuery: UseLazyQuery<any>
  dropdownHeight?: string
}

export const SelectDynamic = ({
  label,
  placeholder,
  name,
  values = [],
  errorMessage,
  valueName,
  labelName,
  className,
  searchFieldName = "name",
  dropdownHeight,
  queryParams = {},
  onChange,
  useLazyQuery,
}: Props) => {
  if (!useLazyQuery || typeof useLazyQuery !== "function" || !useLazyQuery()?.length)
    return <div className={styles.errorText}>Error in "SelectDynamic" component</div> // Additional checking for useLazyQuery

  const [triggerGetData, { isFetching, data }] = useLazyQuery()
  const [searchValue, setSearchValue] = useState("")
  const prevSearchValue = usePrevious(searchValue)
  const dispatch = useAppDispatch()

  const onSetSearchValue = (data) => {
    const { search } = data?.state
    setSearchValue(search)
  }

  const setDebaunceSearchValue = debounce(onSetSearchValue, 600)

  // FOR ZIP SELECT ONLY
  const filterCityStateBasedOnZip = (options: UITypes.Option[], resData: any) => {
    if (!resData?.data?.length) return
    const value = options[0]?.value
    const result = resData?.data?.filter((item: ApiTypes.Model.Zip) => Number(value) === item.id)
    result?.length && dispatch(setCurrentZip(result[0]))
  }

  const onSelectChange = (options) => {
    if (name === "zip") {
      filterCityStateBasedOnZip(options, data)
    }
    onChange && onChange(options)
  }

  useEffect(() => {
    if (searchValue !== prevSearchValue) {
      triggerGetData(searchValue === "" ? queryParams : { [searchFieldName]: searchValue, ...queryParams })
    }
  }, [searchValue, prevSearchValue])

  return (
    <div className={classNames(styles.fieldWrapper, className)}>
      {label && <div className={styles.fieldLabelNew}>{label}</div>}
      <div className={classNames(styles.field)}>
        <DropdownSelect
          options={convertDataToOptions(data, valueName, labelName)}
          loading={isFetching}
          placeholder={placeholder}
          name={name}
          className={classNames("custom", {
            [styles.error]: errorMessage,
          })}
          values={values?.length ? values : []}
          searchFn={setDebaunceSearchValue}
          onChange={onSelectChange}
          dropdownHeight={dropdownHeight}
        />
      </div>
      {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
    </div>
  )
}
