import { WidgetLayout } from "../../../layouts/WidgetLayout"
import styles from "./index.module.scss"
import { BarChart } from "../../common/BarChart"
import classNames from "classnames"
import { useState, useEffect } from "react"
// import { Spinner } from "../../common/Spinner"

const initialState = {
  active: 0,
  inActive: 0,
}

export const WidgetContractors = () => {
  const [widgetData, setWidgetData] = useState(initialState)

  const sumObjectValues = (obj) => {
    let sum = 0
    for (const key in obj) {
      if (obj[key]) {
        sum += obj[key]
      }
    }
    return sum
  }

  useEffect(() => {
    setWidgetData({
      active: 8,
      inActive: 8,
    })
  }, [])

  return (
    <WidgetLayout
      title="Contractors"
      className={styles.widgetWrapper}
      tag={sumObjectValues(widgetData)}
      tagName="Total"
    >
      <div
        className={classNames(styles.widgetContent, {
          // [styles.disabled]: isFetching,
        })}
      >
        <div className={styles.chartValuesWrapper}>
          <span className={classNames(styles.chartValuesTitle, styles.green)}>Active</span>
          <span className={styles.chartValuesTitle}>8 (50%)</span>
        </div>
        <BarChart
          className={styles.chart}
          items={[
            {
              value: widgetData.active,
              color: "#22C55E",
            },
            {
              value: widgetData.inActive,
              color: "#E2E8F0",
            },
          ]}
        />
        {/* {isFetching && (
          <div className={styles.widgetLoader}>
            <Spinner />
          </div>
        )} */}
      </div>
    </WidgetLayout>
  )
}
