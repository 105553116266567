import styles from "../../assets/scss/index.module.scss"
import { FormEvent, useState, useEffect } from "react"
import { createPortal } from "react-dom"
import { ModalHeader } from "../../components/common/ModalHeader"
import { ReactComponent as IconContractor } from "../../assets/svg/modal-icon-contractor.svg"
import { ModalLayout } from "../../layouts/ModalLayout"
import { InputNew } from "../../components/form/InputNew"
import { useAppSelector, useAppDispatch } from "../../store/hooks"
import { toast } from "react-toastify"
import { showAddContractorModal3, showAddContractorModal2 } from "../../store/modalSlise"
import {
  useLazyCreateContractorQuery,
  useLazyCheckServiceTitanQuery,
  useLazyGetContractorsQuery,
  useLazyGetSTMembershipsQuery,
} from "../../store/api/contractorsApi"
import { UITypes } from "../../types"
import { validationInitialState, onFormValidate, addContractor3Schema } from "../../utils/onValidate"
import { InputTenantID } from "../../components/form/InputTenantID"
import { SelectStatic } from "../../components/form/SelectStatic"
import { convertDataToOptions } from "../../utils/convertData"
import { setContractor } from "../../store/tempDataSlise"

const inputValuesInitialState: UITypes.Contractor = {
  stClientID: "",
  stClientSecret: "",
  stAppKey: "",
  stTenant: "",
}

interface Props {
  onCancel: () => void
}

export const AddContractorModal3 = ({ onCancel }: Props) => {
  const [triggerGetContractors] = useLazyGetContractorsQuery()
  const [triggerCreateContractor, { isFetching }] = useLazyCreateContractorQuery()
  const [triggerCheckServiceTitan, { isFetching: isSTFetching }] = useLazyCheckServiceTitanQuery()
  const [triggerGetSTMemberships, { isFetching: isSTMembershipsFetching, data: membershipsData }] =
    useLazyGetSTMembershipsQuery()
  const dispatch = useAppDispatch()
  const contractor = useAppSelector((state) => state.tempData.contractor)
  const requestParams = useAppSelector((state) => state.tempData.requestParams)
  const [inputValues, setInputValues] = useState({ ...inputValuesInitialState, ...contractor })
  const [error, setError] = useState(validationInitialState)
  const [tenantIDStatus, setTenantIDStatus] = useState<UITypes.TenantIDStautus>("check")
  const [memberships, setMemberships] = useState<UITypes.Option[]>([])

  const onInputChange = (event: FormEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = event.currentTarget

    setInputValues((prevState) => {
      return { ...prevState, [name]: type === "checkbox" ? checked : value }
    })
  }

  const onBack = () => {
    dispatch(setContractor({ ...contractor, ...inputValues }))
    dispatch(showAddContractorModal2(true))
    dispatch(showAddContractorModal3(false))
  }

  const prepareData = () => {
    const formData = new FormData()

    formData.append("name", contractor?.name!)
    formData.append("brand", contractor?.brand!)
    formData.append("ein", contractor?.ein!)
    formData.append("address1", contractor?.address1!)
    formData.append("address2", contractor?.address2!)
    formData.append("url", contractor?.url!)
    formData.append("schedule", contractor?.schedule!)
    formData.append("full_name", contractor?.fullName!)
    formData.append("tel1", contractor?.phone!)
    formData.append("email", contractor?.email!)
    contractor?.state && formData.append("state", contractor?.state[0].value! as string)
    contractor?.city && formData.append("city", contractor?.city[0].value! as string)
    contractor?.zip && formData.append("zip", contractor?.zip[0].value! as string)
    formData.append("st_client_id", inputValues.stClientID!)
    formData.append("st_client_secret", inputValues.stClientSecret!)
    formData.append("st_app_key", inputValues.stAppKey!)
    formData.append("st_tenant", inputValues.stTenant!)
    formData.append("is_enabled", "1")
    formData.append("has_st", "1")
    formData.append("is_st_checked", tenantIDStatus === "pass" ? "1" : "")
    formData.append(
      "memberships",
      JSON.stringify(memberships?.map((item) => ({ id: Number(item.value), name: item.label }))),
    )
    formData.append("is_custom", contractor?.isCustom ? "1" : "0")
    formData.append("app_store_link", contractor?.appStoreLink || "")
    formData.append("google_play_link", contractor?.googlePlayLink || "")
    formData.append("phone_for_support", contractor?.phoneForSupport ? "1" : "0")
    formData.append("phone_for_support_call", contractor?.phoneForSupportCall || "")
    formData.append("phone_for_support_text", contractor?.phoneForSupportText || "")

    if (contractor?.logo) {
      formData.append("image", contractor?.logo!)
    }

    return formData
  }

  const onSubmit = async (event) => {
    event.preventDefault()

    if (
      !onFormValidate(
        {
          stClientID: inputValues.stClientID,
          stClientSecret: inputValues.stClientSecret,
          stAppKey: inputValues.stAppKey,
          stTenant: inputValues.stTenant,
        },
        addContractor3Schema,
        setError,
      )
    ) {
      return
    }

    try {
      const res: any = await triggerCreateContractor(prepareData() as any)
      if (res?.error) {
        toast.error(res?.error?.data?.message)
      } else {
        toast.success("Contractor has been successfully added!")
        setInputValues(inputValuesInitialState)
        triggerGetContractors(requestParams === null ? {} : requestParams)
        onCancel()
      }
    } catch (error) {
      console.error(error)
    }
  }

  const tenantIDHandler = () => {
    if (tenantIDStatus === "error") {
      setTenantIDStatus("check")
    }
    if (tenantIDStatus === "check") {
      onTenantIDCheck()
    }
    if (tenantIDStatus === "pass") {
      setTenantIDStatus("check")
    }
  }

  const onTenantIDCheck = () => {
    const { stClientID, stClientSecret, stAppKey, stTenant } = inputValues
    const validationValues = { stClientID, stClientSecret, stAppKey, stTenant }
    if (onFormValidate(validationValues, addContractor3Schema, setError)) {
      sendTenantIDRequest()
    }
  }

  const sendTenantIDRequest = async () => {
    try {
      const res: any = await triggerCheckServiceTitan({
        client_id: inputValues.stClientID,
        client_secret: inputValues.stClientSecret,
        app_key: inputValues.stAppKey,
        tenant_id: inputValues.stTenant,
      })

      if (res?.error) {
        toast.error(res?.error?.data?.message)
        setTenantIDStatus("error")
      } else {
        setTenantIDStatus("pass")
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (tenantIDStatus === "pass") {
      const fetchData = async () => {
        try {
          await triggerGetSTMemberships({
            client_id: inputValues.stClientID,
            client_secret: inputValues.stClientSecret,
            app_key: inputValues.stAppKey,
            tenant_id: inputValues.stTenant,
          })
        } catch (error) {
          console.error(error)
        }
      }

      fetchData()
    }
  }, [tenantIDStatus])

  return (
    <>
      {createPortal(
        <ModalLayout
          width="450px"
          confirmButtonText="Save"
          cancelButtonText="Back"
          onConfirm={onSubmit}
          onClose={onCancel}
          confirmButtonType="submit"
          isDisabled={tenantIDStatus !== "pass"}
          isFetching={isFetching}
          onCancel={onBack}
        >
          <ModalHeader title="Add Contractor" subtitle="Step 3/3">
            <IconContractor />
          </ModalHeader>
          <div className={styles.modalContent}>
            <InputNew
              label="Client ID"
              placeholder="Enter client ID"
              name="stClientID"
              value={inputValues.stClientID}
              onChange={onInputChange}
              errorMessage={error.field === "stClientID" ? error.message : ""}
            />
            <InputNew
              label="Client Secret"
              placeholder="Enter client secret"
              name="stClientSecret"
              value={inputValues.stClientSecret}
              onChange={onInputChange}
              errorMessage={error.field === "stClientSecret" ? error.message : ""}
            />
            <InputNew
              label="API Key"
              placeholder="Enter API Key"
              name="stAppKey"
              value={inputValues.stAppKey}
              onChange={onInputChange}
              errorMessage={error.field === "stAppKey" ? error.message : ""}
            />
            <InputTenantID
              status={isSTFetching ? "loading" : tenantIDStatus}
              label="Tenant ID"
              placeholder="Tenant ID"
              name="stTenant"
              value={inputValues.stTenant}
              onChange={onInputChange}
              buttonHandler={tenantIDHandler}
              errorMessage={error.field === "stTenant" ? error.message : ""}
            />
            <SelectStatic
              label="Memberships"
              placeholder="Select Membership"
              multi={true}
              isLoading={isSTMembershipsFetching}
              options={convertDataToOptions(membershipsData, "id", "name")}
              onChange={setMemberships}
            />
          </div>
        </ModalLayout>,
        document.body,
      )}
    </>
  )
}
