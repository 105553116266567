import styles from "../../../assets/scss/index.module.scss"
import logo from "../../../assets/svg/logo-white.svg"
import { Link } from "react-router-dom"
import { useState, useEffect } from "react"
import { NavLink } from "../../common/NavLink"
import { UserMenu } from "../../common/UserMenu"
import { ReactComponent as IconOrders } from "../../../assets/svg/menu-orders.svg"
import { ReactComponent as IconAlerts } from "../../../assets/svg/menu-alerts.svg"
import { ReactComponent as IconCustomers } from "../../../assets/svg/menu-customers.svg"
import { ReactComponent as IconDashboard } from "../../../assets/svg/menu-dashboard.svg"
import { ReactComponent as IconMessages } from "../../../assets/svg/menu-messages.svg"
import { ReactComponent as IconTechnicians } from "../../../assets/svg/menu-technicians.svg"
import { ReactComponent as IconDiagnostics } from "../../../assets/svg/menu-diagnostics.svg"
import classNames from "classnames"
import { Hamburger } from "../../common/Hamburger"

export const ContractorTopbar = () => {
  const [isSticky, setSticky] = useState(false)
  const [isOpen, setOpen] = useState(false)

  const closeMenu = () => {
    setOpen(false)
  }

  const handleScroll = () => {
    const distance = window.scrollY

    if (distance > 0 && !isSticky) {
      setSticky(true)
    } else if (distance === 0 && isSticky) {
      setSticky(false)
    }
  }

  useEffect(() => {
    const setStickyOnScroll = () => handleScroll()

    window.addEventListener("scroll", setStickyOnScroll)

    return () => {
      window.removeEventListener("scroll", setStickyOnScroll)
    }
  }, [isSticky])

  return (
    <header className={classNames(styles.topbar, { [styles.sticky]: isSticky })}>
      <Link className={styles.logoWrapper} to="/">
        <img className={styles.logo} src={logo} alt="logo" />
      </Link>
      <nav className={classNames(styles.menuWrapper, { [styles.opened]: isOpen })}>
        <NavLink to="/dashboard" className={styles.menuItem} activeClassName={styles.active} onClick={closeMenu}>
          <IconDashboard className={styles.menuIcon} />
          Dashboard
        </NavLink>
        <NavLink to="/customers" className={styles.menuItem} activeClassName={styles.active} onClick={closeMenu}>
          <IconCustomers className={styles.menuIcon} />
          Customers
        </NavLink>
        <NavLink to="/orders" className={styles.menuItem} activeClassName={styles.active} onClick={closeMenu}>
          <IconOrders className={styles.menuIcon} />
          Orders
        </NavLink>
        <NavLink to="/technicians" className={styles.menuItem} activeClassName={styles.active} onClick={closeMenu}>
          <IconTechnicians className={styles.menuIcon} />
          Technicians
        </NavLink>
        <NavLink to="/messages" className={styles.menuItem} activeClassName={styles.active} onClick={closeMenu}>
          <IconMessages className={styles.menuIcon} />
          Messages
        </NavLink>
        <NavLink to="/alerts" className={styles.menuItem} activeClassName={styles.active} onClick={closeMenu}>
          <IconAlerts className={styles.menuIcon} />
          Alerts
        </NavLink>
        {/* <NavLink to="/diagnostics" className={styles.menuItem} activeClassName={styles.active}>
          <IconDiagnostics className={styles.menuIconStroke} />
          Diagnostics
        </NavLink> */}
      </nav>
      <Hamburger onClick={() => setOpen(!isOpen)} isOpen={isOpen} />
      <UserMenu />
    </header>
  )
}
