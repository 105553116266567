import { useState, useEffect } from "react"
import { WidgetLayout } from "../../../layouts/WidgetLayout"
import styles from "./index.module.scss"
import { DoughnutChart } from "../../common/DoughnutChart"
import classNames from "classnames"
import { Spinner } from "../../common/Spinner"
import { useLazyGetStatusQuery } from "../../../store/api/widgetsApi"

const initialState = {
  offlineBroken: 0,
  enrolled: 0,
  missing: 0,
  inventory: 0,
  total: 0,
  transferring: 0,
  warranty: 0,
}

export const WidgetStatus = () => {
  const [triggerGetStatus, { isFetching }] = useLazyGetStatusQuery()
  const [widgetData, setWidgetData] = useState(initialState)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await triggerGetStatus()
        if (res?.data) {
          setWidgetData({
            offlineBroken: res?.data?.data?.installed_devices?.offline_broken || 0,
            enrolled: res?.data?.data?.installed_devices?.enrolled || 0,
            missing: res?.data?.data?.missing || 0,
            inventory: res?.data?.data?.inventory || 0,
            total: res?.data?.data?.installed_devices?.total || 0,
            transferring: res?.data?.data?.transferring || 0,
            warranty: res?.data?.data?.warranty || 0,
          })
        }
      } catch (error) {
        console.error(error)
      }
    }

    fetchData()
  }, [])

  return (
    <WidgetLayout title="Status" className={styles.widgetStatus}>
      <div
        className={classNames(styles.widgetContent, {
          [styles.disabled]: isFetching,
        })}
      >
        <div className={styles.chartWrapper}>
          <div className={styles.chart}>
            <DoughnutChart
              values={[
                widgetData.enrolled,
                widgetData.offlineBroken,
                widgetData.inventory,
                widgetData.missing,
                widgetData.transferring,
                widgetData.warranty,
              ]}
              colors={["#86efac", "#e0e7ff", "#fcceee", "#f59e0b", "#2563EB", "#2db2c8"]}
            />
            <div className={styles.widgetMainValue}>
              <span className={styles.widgetNote}>DEVICES</span>
              <div className={styles.widgetValueWrapper}>
                {widgetData.total + widgetData.missing + widgetData.inventory}
              </div>
            </div>
          </div>
          <div className={styles.chartNotesWrapper}>
            <div className={styles.chartNotesSection}>
              <div className={styles.chartNote}>
                <span className={styles.chartNoteCategory}>Units installed</span>
                <span className={styles.chartNoteValue}>{widgetData.total}</span>
              </div>
              <span className={classNames(styles.chartNoteLine, styles.hideChartLine)}></span>
              <div className={classNames(styles.chartNote, styles.enrolled)}>
                <span className={styles.chartNoteCategory}>Online</span>
                <span className={styles.chartNoteValue}>{widgetData.enrolled}</span>
              </div>
              <span className={classNames(styles.chartNoteLine, styles.hideChartLine)}></span>
              <div className={classNames(styles.chartNote, styles.offline)}>
                <span className={styles.chartNoteCategory}>Offline</span>
                <span className={styles.chartNoteValue}>{widgetData.offlineBroken}</span>
              </div>
              <span className={classNames(styles.chartNoteLine, styles.hideChartLine)}></span>
            </div>
            <div className={styles.chartNotesSection}>
              <div className={classNames(styles.chartNote, styles.inventory)}>
                <span className={styles.chartNoteCategory}>In Inventory</span>
                <span className={styles.chartNoteValue}>{widgetData.inventory}</span>
              </div>
              <span className={styles.chartNoteLine}></span>
              <div className={classNames(styles.chartNote, styles.missing)}>
                <span className={styles.chartNoteCategory}>Missing</span>
                <span className={styles.chartNoteValue}>{widgetData.missing}</span>
              </div>
              <span className={styles.chartNoteLine}></span>
              <div className={classNames(styles.chartNote, styles.transferring)}>
                <span className={styles.chartNoteCategory}>Transferring</span>
                <span className={styles.chartNoteValue}>{widgetData.transferring}</span>
              </div>
              <span className={styles.chartNoteLine}></span>
              <div className={classNames(styles.chartNote, styles.warranty)}>
                <span className={styles.chartNoteCategory}>Warranty</span>
                <span className={styles.chartNoteValue}>{widgetData.warranty}</span>
              </div>
            </div>
          </div>
        </div>
        {isFetching && (
          <div className={styles.widgetLoader}>
            <Spinner />
          </div>
        )}
      </div>
    </WidgetLayout>
  )
}
