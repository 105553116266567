import styles from "./index.module.scss"
import { Table } from "../../table/Table"
import { UITypes } from "../../../types"
import { useAppDispatch } from "../../../store/hooks"
import { showDeleteMessageTemplateModal } from "../../../store/modalSlise"
import { useLazyGetMessageTemplatesQuery } from "../../../store/api/contractorsApi"
import { useEffect } from "react"
import { toast } from "react-toastify"
import { convertMessageTemplatesToTableRows } from "../../../utils/convertData"
import { useNavigate, useSearchParams } from "react-router-dom"
import { setCurrentTemplateID } from "../../../store/tempDataSlise"

// TABLE HEADER
const tableHeaderCells: UITypes.TableHeaderCell[] = [
  {
    title: "Title",
  },
  {
    title: "Creation date",
    size: "small",
  },
  {
    title: "Message",
  },
  {
    title: "ID",
    size: "tiny",
  },
]

export const MessageTemplatesList = () => {
  const [triggerGetMessageTemplates, { isFetching, data }] = useLazyGetMessageTemplatesQuery()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const id = searchParams.get("id")

  const onDeleteClick = (id) => {
    dispatch(setCurrentTemplateID(id))
    dispatch(showDeleteMessageTemplateModal(true))
    navigate(`/messages/create-template`)
  }

  const onEditClick = (id) => {
    navigate(`/messages/edit-template?id=${id}`)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        await triggerGetMessageTemplates()
      } catch (error) {
        console.error(error)
      }
    }

    fetchData()
  }, [])

  return (
    <div className={styles.templateList}>
      <Table
        headerCells={tableHeaderCells}
        className={styles.table}
        isLoading={isFetching}
        currentItemID={id}
        bodyRows={convertMessageTemplatesToTableRows(data?.data)}
        onDeleteClick={onDeleteClick}
        onEditClick={onEditClick}
      />
    </div>
  )
}
