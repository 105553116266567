import styles from "./index.module.scss"
import { PropsWithChildren, FormEvent } from "react"
import classNames from "classnames"

interface Props extends PropsWithChildren {
  isChecked?: boolean
  name?: string
  isReverse?: boolean
  className?: string
  id: string
  onChange?: (event: FormEvent<HTMLInputElement>) => void
}

export const CheckboxNew = ({ isChecked, isReverse, children, className, name, id, onChange }: Props) => {
  return (
    <div
      className={classNames(styles.checkboxNewWrapper, className, {
        [styles.reverse]: isReverse,
      })}
    >
      <input
        name={name}
        type="checkbox"
        className={styles.checkboxNew}
        checked={isChecked}
        onChange={onChange}
        id={id}
      />
      <label htmlFor={id} className={styles.checkboxNewLabel}></label>
      {children && <div className={styles.checkboxNewText}>{children}</div>}
    </div>
  )
}
