import styles from "./index.module.scss"
import { FormEvent } from "react"
import classNames from "classnames"

interface Props {
  placeholder?: string
  label?: string
  errorMessage?: string
  value?: string
  name?: string
  id?: string
  onChange?: (event: FormEvent<HTMLTextAreaElement>) => void
}

export const Textarea = ({ placeholder, label, errorMessage, value, name, onChange }: Props) => {
  return (
    <div className={styles.fieldWrapper}>
      <div className={styles.fieldLabel}>{label}</div>
      <div className={styles.field}>
        <textarea
          className={classNames(styles.textarea, { [styles.error]: errorMessage })}
          placeholder={placeholder}
          value={value}
          name={name}
          onChange={onChange}
        ></textarea>
      </div>
      {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
    </div>
  )
}
