import styles from "./index.module.scss"
import { ReactComponent as IconPhoto } from "../../../assets/svg/icon-photo.svg"
import { useState, useEffect, useRef, MutableRefObject } from "react"
import { getFileUrl } from "../../../utils/getFileUrl"
import classNames from "classnames"

interface Props {
  onSetFile: (value: File[]) => void
  imageUrl?: string
  value?: File[]
  name?: string
  label?: string
  defaultLink?: string
  errorMessage?: string
}

export const InputLogo = ({ label, errorMessage, value, name, defaultLink, imageUrl, onSetFile }: Props) => {
  const [isDragging, setIsDragging] = useState(false)
  const [uploadedFiles, setUploadedFiles] = useState<File[] | undefined>(value ? value : undefined)
  const uploadAvatarRef: MutableRefObject<HTMLInputElement | null> = useRef(null)

  const handleDragOver = (e) => {
    e.preventDefault()
    setIsDragging(true)
  }

  const handleDragLeave = () => {
    setIsDragging(false)
  }

  const handleDrop = (e) => {
    e.preventDefault()
    setIsDragging(false)

    const files = e.dataTransfer.files
    setUploadedFiles(files)
  }

  const onInputChange = (event) => {
    const files = event?.target?.files
    onSetFile(files)
    setUploadedFiles(event?.target?.files as any)
  }

  useEffect(() => {
    setUploadedFiles(value)
  }, [value])

  return (
    <div className={styles.fieldWrapper}>
      <div className={styles.fieldLabelNew}>{label}</div>
      <div className={classNames(styles.field, styles.logoField)}>
        <div className={styles.imageWrapper}>
          {uploadedFiles?.length ? (
            <img
              src={uploadedFiles ? getFileUrl(uploadedFiles[0]) : defaultLink}
              alt="file"
              className={styles.uploadedImage}
            />
          ) : imageUrl ? (
            <img src={imageUrl} alt="file" className={styles.uploadedImage} />
          ) : (
            <IconPhoto className={styles.icon} />
          )}
        </div>
        <input
          type="file"
          id="file"
          name={name}
          accept="image/png,image/svg+xml"
          className={styles.inputFile}
          onChange={onInputChange}
          ref={uploadAvatarRef}
        />
        <label
          htmlFor="file"
          className={classNames(styles.fileWrapper, {
            [styles.dragging]: isDragging,
            [styles.error]: errorMessage,
          })}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <div className={styles.labelText}>
            <span>Click to upload</span> or drag and drop <br />
            Format: PNG, Aspect ratio 16:6 <br />
            Max size: 750x281px
            <br />
            {/* The logo file name should match the Company name */}
          </div>
        </label>
      </div>
      {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
    </div>
  )
}
