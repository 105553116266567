import styles from "../../assets/scss/index.module.scss"
import { FormEvent, useState } from "react"
import { createPortal } from "react-dom"
import { ModalHeader } from "../../components/common/ModalHeader"
import { ReactComponent as IconContractor } from "../../assets/svg/modal-icon-contractor.svg"
import { ModalLayout } from "../../layouts/ModalLayout"
import { InputNew } from "../../components/form/InputNew"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { toast } from "react-toastify"
import { showAddContractorModal1, showAddContractorModal2, showAddContractorModal3 } from "../../store/modalSlise"
import { setContractor, setCurrentZip } from "../../store/tempDataSlise"
import { useLazyCreateContractorQuery, useLazyGetContractorsQuery } from "../../store/api/contractorsApi"
import { UITypes } from "../../types"
import { InputLogo } from "../../components/form/InputLogo"
import { validationInitialState, onFormValidate, addContractor2Schema } from "../../utils/onValidate"
import { InputPhone } from "../../components/form/InputPhone"
import { urlPatternWithoutHttpPrefix } from "../../utils/regularExpressions"
import { ContractorCustomApp } from "../components/contractorCustomApp"
import { ContractorPhoneSupport } from "../components/contractorPhoneSupport"

const inputValuesInitialState: UITypes.Contractor = {
  url: "",
  schedule: "",
  fullName: "",
  phone: "",
  email: "",
  appStoreLink: "",
  googlePlayLink: "",
  phoneForSupport: true,
  phoneForSupportCall: "",
  phoneForSupportText: "",
}

interface Props {
  onCancel: () => void
}

export const AddContractorModal2 = ({ onCancel }: Props) => {
  const [triggerCreateContractor, { isFetching }] = useLazyCreateContractorQuery()
  const [triggerGetContractors] = useLazyGetContractorsQuery()
  const contractor = useAppSelector((state) => state.tempData.contractor)
  const requestParams = useAppSelector((state) => state.tempData.requestParams)
  const dispatch = useAppDispatch()
  const [imageFile, setImageFile] = useState(contractor?.logo || undefined)
  const [inputValues, setInputValues] = useState({ ...inputValuesInitialState, ...contractor })
  const [error, setError] = useState(validationInitialState)

  const onInputChange = (event: FormEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = event.currentTarget

    setInputValues((prevState) => {
      return {
        ...prevState,
        [name]: type === "checkbox" ? checked : value,
        ...(name === "isCustom" && !checked ? { appStoreLink: "", googlePlayLink: "" } : {}),
        ...(name === "phoneForSupport" && checked ? { phoneForSupportCall: "", phoneForSupportText: "" } : {}),
      }
    })
  }

  const onInputBlur = (event: FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget

    if (urlPatternWithoutHttpPrefix.test(value)) {
      const urlPattern = /^(http:\/\/|https:\/\/)/

      setInputValues((prevState) => {
        return { ...prevState, [name]: `${urlPattern.test(value) ? "" : "https://"}${value}` }
      })
    }
  }

  const prepareData = () => {
    const formData = new FormData()

    formData.append("name", contractor?.name!)
    formData.append("brand", contractor?.brand!)
    formData.append("ein", contractor?.ein!)
    formData.append("address1", contractor?.address1!)
    formData.append("address2", contractor?.address2!)
    formData.append("url", inputValues.url!)
    formData.append("schedule", inputValues.schedule!)
    formData.append("full_name", inputValues.fullName!)
    formData.append("tel1", inputValues.phone!)
    formData.append("email", inputValues.email!)
    contractor?.state && formData.append("state", contractor?.state[0].value! as string)
    contractor?.city && formData.append("city", contractor?.city[0].value! as string)
    contractor?.zip && formData.append("zip", contractor?.zip[0].value! as string)
    formData.append("is_enabled", "1")
    formData.append("is_custom", inputValues.isCustom ? "1" : "0")
    imageFile && formData.append("image", imageFile)
    formData.append("app_store_link", inputValues.appStoreLink || "")
    formData.append("google_play_link", inputValues.googlePlayLink || "")
    formData.append("phone_for_support", inputValues.phoneForSupport ? "1" : "0")
    formData.append("phone_for_support_call", inputValues.phoneForSupportCall || "")
    formData.append("phone_for_support_text", inputValues.phoneForSupportText || "")

    return formData
    // serialize
  }

  const sendRequest = async () => {
    try {
      const res: any = await triggerCreateContractor(prepareData() as any)

      if (res?.error) {
        toast.error(res?.error?.data?.message)
      } else {
        toast.success("Contractor has been successfully added!")
        setInputValues(inputValuesInitialState)
        dispatch(setCurrentZip(undefined))
        triggerGetContractors(requestParams === null ? {} : requestParams)
        onCancel()
      }
    } catch (error) {
      console.error(error)
    }
  }

  const onSubmit = (event) => {
    event.preventDefault()
    dispatch(setContractor({ ...contractor, ...inputValues, ...{ logo: imageFile } }))

    if (
      !onFormValidate(
        {
          url: inputValues.url,
          schedule: inputValues.schedule,
          fullName: inputValues.fullName,
          phone: inputValues.phone,
          email: inputValues.email,
          isCustom: inputValues.isCustom,
          appStoreLink: inputValues.appStoreLink,
          googlePlayLink: inputValues.googlePlayLink,
          phoneForSupport: inputValues.phoneForSupport,
          phoneForSupportCall: inputValues.phoneForSupportCall,
          phoneForSupportText: inputValues.phoneForSupportText,
          logo: imageFile,
        },
        addContractor2Schema,
        setError,
      )
    )
      return

    if (contractor?.isServiceTitan) {
      dispatch(showAddContractorModal2(false))
      dispatch(showAddContractorModal3(true))
    } else {
      sendRequest()
    }
  }

  const onBack = () => {
    dispatch(setContractor({ ...contractor, ...inputValues, ...{ logo: imageFile } }))
    dispatch(showAddContractorModal1(true))
    dispatch(showAddContractorModal2(false))
  }

  return (
    <>
      {createPortal(
        <ModalLayout
          width="450px"
          cancelButtonText="Back"
          confirmButtonText={contractor?.isServiceTitan ? "Next" : "Save"}
          confirmButtonType="submit"
          isFetching={isFetching}
          onConfirm={onSubmit}
          onClose={onCancel}
          onCancel={onBack}
        >
          <ModalHeader title="Add Contractor" subtitle={contractor?.isServiceTitan ? "Step 2/3" : "Step 2/2"}>
            <IconContractor />
          </ModalHeader>
          <div className={styles.modalContent}>
            <InputNew
              label="Company web URL"
              placeholder="Enter URL"
              name="url"
              value={inputValues.url}
              onChange={onInputChange}
              onBlur={onInputBlur}
              errorMessage={error.field === "url" ? error.message : ""}
            />
            <InputNew
              label="Schedule URL"
              placeholder="Enter schedule URL"
              name="schedule"
              value={inputValues.schedule}
              onChange={onInputChange}
              onBlur={onInputBlur}
              errorMessage={error.field === "schedule" ? error.message : ""}
            />
            <InputNew
              label="Full Name"
              placeholder="Enter Full Name"
              name="fullName"
              value={inputValues.fullName}
              onChange={onInputChange}
              errorMessage={error.field === "fullName" ? error.message : ""}
            />
            <InputPhone
              label="Phone number"
              placeholder="Enter phone"
              name="phone"
              value={inputValues.phone}
              onChange={(value) => setInputValues({ ...inputValues, ...{ phone: value } })}
              errorMessage={error.field === "phone" ? error.message : ""}
            />
            <ContractorPhoneSupport
              phoneForSupport={inputValues.phoneForSupport}
              phoneForSupportCall={inputValues.phoneForSupportCall}
              phoneForSupportText={inputValues.phoneForSupportText}
              inputValues={inputValues}
              error={error}
              onInputChange={onInputChange}
              setInputValues={setInputValues}
            />
            <InputNew
              label="Email"
              placeholder="Enter email address"
              name="email"
              value={inputValues.email}
              onChange={onInputChange}
              errorMessage={error.field === "email" ? error.message : ""}
            />
            <InputLogo
              name="logo"
              errorMessage={error.field === "logo" ? error.message : ""}
              label="Company logo"
              value={imageFile ? ([imageFile] as unknown as File[]) : undefined}
              onSetFile={(files) => setImageFile(files[0] as any)}
            />
            <ContractorCustomApp
              customAppEnabled={inputValues.isCustom || false}
              appStoreLink={inputValues.appStoreLink}
              googlePlayLink={inputValues.googlePlayLink}
              error={error}
              onInputChange={onInputChange}
              onInputBlur={onInputBlur}
            />
          </div>
        </ModalLayout>,
        document.body,
      )}
    </>
  )
}
