import styles from "./index.module.scss"
import { FormEvent, useState, useEffect } from "react"
import { createPortal } from "react-dom"
import { ModalHeader } from "../../components/common/ModalHeader"
import { ReactComponent as IconHomeowner } from "../../assets/svg/modal-icon-edit-homeowner.svg"
import { ModalLayout } from "../../layouts/ModalLayout"
import { InputNew } from "../../components/form/InputNew"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { setCurrentZip } from "../../store/tempDataSlise"
import {
  validationInitialState,
  onFormValidate,
  editCustomerCommonSchema,
  editCustomerDeviceSchema,
} from "../../utils/onValidate"
import { SelectDynamic } from "../../components/form/SelectDynamic"
import { SelectStatic } from "../../components/form/SelectStatic"
import { UITypes } from "../../types"
import { v4 as uuidv4 } from "uuid"
import { convertDataToOptions, convertEditDevicesToState } from "../../utils/convertData"
import {
  useLazyGetCitiesQuery,
  useLazyGetZipCodesQuery,
  useLazyGetStatesQuery,
  useLazyGetResidentTypesQuery,
  useLazyGetDeviceLocationsQuery,
} from "../../store/api/dictionaryApi"
import { useLazyUpdateClientQuery, useLazyGetClientsQuery } from "../../store/api/clientApi"
import classNames from "classnames"
import { toast } from "react-toastify"
import { SelectSubOptions } from "../../components/form/SelectDynamic/selectsuboptions"

const commonValuseState = {
  fullName: "",
  homeownerID: undefined,
  email: "",
  phone: "",
}

const deviceValuesState: UITypes.Device = {
  deviceName: "",
  address1: "",
  address2: "",
  serialNumber: "",
  installationType: [],
  zip: [],
  state: [],
  city: [],
  residentialType: [],
  deviceLocation: [],
  membership: "",
}

interface Props {
  onCancel: () => void
}

export const EditCustomerModal = ({ onCancel }: Props) => {
  const [triggerUpdateClientQuery, { isFetching }] = useLazyUpdateClientQuery()
  const [triggerGetClients] = useLazyGetClientsQuery()
  const [triggerGetDeviceLocations] = useLazyGetDeviceLocationsQuery()
  const dispatch = useAppDispatch()
  const [devices, setDevices] = useState<UITypes.Device[]>([])
  const [rtLocations, setRtLocations] = useState({})
  const [currentTab, setCurrentTab] = useState<number>(0)
  const [commonValues, setCommonValues] = useState(commonValuseState)
  const [deviceValues, setDeviceValues] = useState(deviceValuesState)
  const currentZip = useAppSelector((state) => state.tempData.currentZip)
  const editableClient = useAppSelector((state) => state.tempData.editableClient)
  const [error, setError] = useState(validationInitialState)
  const [errorDeviceID, setErrorDeviceID] = useState<number | undefined>(undefined)

  const onCancelClick = () => {
    dispatch(setCurrentZip(undefined))
    onCancel()
  }

  const onTabChange = (tab: number) => {
    if (tab === currentTab) return
    saveChanges()
    setCurrentTab(tab)
  }

  // UPDATE DEVICES STATE
  const saveChanges = () => {
    const _devices = devices?.map((obj) => {
      if (obj?.id === deviceValues?.id) {
        return deviceValues
      }
      return obj
    })

    setDevices(_devices)
    return _devices
  }

  // EDIT VALUES
  const onCommonValuesChange = (event: FormEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = event.currentTarget

    setCommonValues((prevState) => {
      return { ...prevState, [name]: type === "checkbox" ? checked : value }
    })
  }

  const onDeviceValuesChange = (event: FormEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = event.currentTarget

    setDeviceValues((prevState) => {
      return { ...prevState, [name]: type === "checkbox" ? checked : value }
    })
  }

  const prepareData = (devicesData) => {
    return {
      client: {
        full_name: commonValues.fullName,
      },
      devices: devicesData?.map((item) => ({
        sn: item?.serialNumber,
        name: item?.deviceName,
        address1: item?.address1,
        address2: item?.address2,
        state: item?.state?.[0] && Number(item?.state?.[0]?.value),
        city: item?.city?.[0] && Number(item?.city?.[0]?.value),
        zip_code: item?.zip?.[0] && item?.zip?.[0]?.label,
        where_installed_id: item?.deviceLocation?.[0] && Number(item?.deviceLocation?.[0]?.value),
        resident_type_id: item?.residentialType?.[0] && Number(item?.residentialType?.[0]?.value),
        installation_type: item?.installationType?.[0] && item?.installationType?.[0]?.value,
      })),
    }
  }

  const sendLocationRequest = (params: { name?: string | undefined; rt?: string | undefined }) => {
    try {
      triggerGetDeviceLocations(params).then((res: any) => {
        if (res?.error) {
          toast.error(res?.error?.data?.message)
        }
        if (res?.data) {
          setRtLocations(res?.data)
        }
      })
    } catch (error) {
      console.error(error)
    }
  }
  // SEND REQUEST
  const sendRequest = async () => {
    try {
      const res: any = await triggerUpdateClientQuery({
        body: prepareData(saveChanges()) as any,
        id: editableClient?.id!,
      })

      if (res?.isSuccess) {
        toast.success("Customer has been successfully edited!")
        setCommonValues(commonValuseState)
        setDeviceValues(deviceValuesState)
        onCancel()
        dispatch(setCurrentZip(undefined))
        triggerGetClients({})
      }
      if (res?.error) {
        toast.error(res?.error?.data?.message)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const onSubmit = async (event) => {
    event.preventDefault()
    const _devices = saveChanges()

    // VALIDATION
    if (!onFormValidate(commonValues, editCustomerCommonSchema, setError)) return

    const isAllow = _devices?.every((item) => {
      const result = onFormValidate(item, editCustomerDeviceSchema, setError)

      if (!result) {
        setErrorDeviceID(item?.id!)
      } else {
        setErrorDeviceID(undefined)
      }

      return result
    })

    if (!isAllow) return

    sendRequest()
  }

  // SET CURRENT TAB
  useEffect(() => {
    if (!devices?.length) return
    setDeviceValues(devices[currentTab])
  }, [currentTab, devices])

  // SWITCH TAB WHEN VALIDATION ERROR IS HAPPEN
  useEffect(() => {
    if (errorDeviceID !== deviceValues?.id && error?.field) {
      const index = devices.findIndex((item) => item?.id === errorDeviceID)
      setCurrentTab(index)
    }
  }, [error, errorDeviceID])

  // FILL IN CITY AND STATE SELECT BASED ON ZIP
  useEffect(() => {
    if (currentZip?.city) {
      const city = convertDataToOptions({ data: [currentZip.city] }, "id", "name")
      const state = convertDataToOptions({ data: [currentZip.state] }, "id", "name")

      if (city && state) {
        setDeviceValues({
          ...deviceValues,
          ...{
            city,
            state,
          },
        })
      }
    }
  }, [currentZip])

  useEffect(() => {
    const rtParam = deviceValues.residentialType?.[0]?.label
    let params = {}
    if (rtParam) {
      params = {
        rt: rtParam,
      }
    }
    sendLocationRequest(params)
  }, [deviceValues.residentialType])

  // INITIAL SET STATE
  useEffect(() => {
    setCommonValues({
      fullName: editableClient?.full_name ? editableClient?.full_name : "",
      homeownerID: editableClient?.id ? editableClient?.id : (undefined as any),
      email: editableClient?.email ? editableClient?.email : "",
      phone: editableClient?.phone_number ? editableClient?.phone_number : "",
    })

    if (editableClient?.devices?.length) {
      const _devices = convertEditDevicesToState(editableClient?.devices)
      setDevices(_devices)
      setDeviceValues(_devices[0])
    }
  }, [])

  return (
    <>
      {createPortal(
        <ModalLayout
          width="1252px"
          confirmButtonText="Save"
          confirmButtonType="submit"
          onConfirm={onSubmit}
          onCancel={onCancelClick}
          buttonSize="auto"
          isFetching={isFetching}
        >
          <ModalHeader title={`Edit Customer ${commonValues.homeownerID}`}>
            <IconHomeowner />
          </ModalHeader>
          <div className={styles.modalContent}>
            <div className={styles.modalContentWrapper}>
              <aside className={styles.modalSidebar}>
                <h4 className={styles.sidebarSubtitle}>Customer information</h4>
                <div className={styles.modalFieldWrapper}>
                  <span className={styles.modalFieldLabel}>Customer ID</span>
                  <span className={styles.modalFieldValue}>{commonValues.homeownerID}</span>
                </div>
                <div className={styles.modalFieldWrapper}>
                  <span className={styles.modalFieldLabel}>Full Name</span>
                  <InputNew
                    placeholder="Enter Full Name"
                    name="fullName"
                    value={commonValues.fullName}
                    onChange={onCommonValuesChange}
                    errorMessage={error.field === "fullName" ? error.message : ""}
                  />
                </div>
                <div className={styles.modalFieldWrapper}>
                  <span className={styles.modalFieldLabel}>Devices</span>
                  <span className={styles.modalFieldValue}>{editableClient?.devices?.length}</span>
                </div>
                <div className={styles.modalFieldWrapper}>
                  <span className={styles.modalFieldLabel}>Email</span>
                  <span className={styles.modalFieldValue}>{commonValues?.email}</span>
                </div>
                <div className={styles.modalFieldWrapper}>
                  <span className={styles.modalFieldLabel}>Phone number</span>
                  <span className={styles.modalFieldValue}>{commonValues?.phone}</span>
                </div>
              </aside>
              <main className={styles.modalMain}>
                <div className={styles.tabsWrapper}>
                  {devices?.map((item, index) => (
                    <span
                      key={uuidv4()}
                      onClick={() => onTabChange(index)}
                      className={classNames(styles.tab, {
                        [styles.active]: devices?.length > 1 && currentTab === index,
                      })}
                    >
                      Device {index + 1}
                    </span>
                  ))}
                </div>
                <div className={styles.modalMainContent}>
                  <div className={styles.modalMainContentColumn}>
                    <div className={styles.modalFieldWrapper}>
                      <span className={styles.modalFieldLabel}>Device name</span>
                      <InputNew
                        placeholder="Enter Device name"
                        name="deviceName"
                        value={deviceValues.deviceName}
                        onChange={onDeviceValuesChange}
                        errorMessage={error.field === "deviceName" ? error.message : ""}
                      />
                    </div>
                    <div className={styles.modalFieldWrapper}>
                      <span className={styles.modalFieldLabel}>Address 1</span>
                      <InputNew
                        placeholder="Enter Address"
                        name="address1"
                        value={deviceValues.address1}
                        onChange={onDeviceValuesChange}
                        errorMessage={error.field === "address1" ? error.message : ""}
                      />
                    </div>
                    <div className={styles.modalFieldWrapper}>
                      <span className={styles.modalFieldLabel}>Address 2</span>
                      <InputNew
                        placeholder="Enter Address"
                        name="address2"
                        value={deviceValues.address2}
                        onChange={onDeviceValuesChange}
                        errorMessage={error.field === "address2" ? error.message : ""}
                      />
                    </div>
                    <div className={styles.modalFieldWrapper}>
                      <span className={styles.modalFieldLabel}>Zip Code</span>
                      <SelectDynamic
                        valueName="id"
                        labelName="code"
                        name="zip"
                        searchFieldName="code"
                        useLazyQuery={useLazyGetZipCodesQuery}
                        placeholder="Enter Zip Code"
                        values={deviceValues.zip}
                        onChange={(value) => setDeviceValues({ ...deviceValues, ...{ zip: value } })}
                        errorMessage={error.field === "zip" ? error.message : ""}
                      />
                    </div>
                    <div className={styles.modalFieldWrapper}>
                      <span className={styles.modalFieldLabel}>City</span>
                      <SelectDynamic
                        valueName="id"
                        labelName="name"
                        name="city"
                        useLazyQuery={useLazyGetCitiesQuery}
                        placeholder="Enter city"
                        values={deviceValues.city}
                        onChange={(value) => setDeviceValues({ ...deviceValues, ...{ city: value } })}
                        errorMessage={error.field === "city" ? error.message : ""}
                      />
                    </div>
                    <div className={styles.modalFieldWrapper}>
                      <span className={styles.modalFieldLabel}>State</span>
                      <SelectDynamic
                        valueName="id"
                        labelName="name"
                        placeholder="Enter state"
                        name="state"
                        useLazyQuery={useLazyGetStatesQuery}
                        values={deviceValues.state}
                        onChange={(value) => setDeviceValues({ ...deviceValues, ...{ state: value } })}
                        errorMessage={error.field === "state" ? error.message : ""}
                      />
                    </div>
                  </div>
                  <div className={styles.modalMainContentColumn}>
                    <div className={styles.modalFieldWrapper}>
                      <span className={classNames(styles.modalFieldLabel, styles.wider)}>S/N</span>
                      <span className={styles.modalFieldValue}>{deviceValues.serialNumber}</span>
                    </div>

                    <div className={styles.modalFieldWrapper}>
                      <span className={classNames(styles.modalFieldLabel, styles.wider)}>Residence type</span>
                      <SelectDynamic
                        placeholder="Select residence type"
                        useLazyQuery={useLazyGetResidentTypesQuery}
                        valueName="id"
                        labelName="name"
                        values={deviceValues.residentialType}
                        onChange={(value) => setDeviceValues({ ...deviceValues, ...{ residentialType: value } })}
                        errorMessage={error.field === "residentialType" ? error.message : ""}
                      />
                    </div>
                    <div className={styles.modalFieldWrapper}>
                      <span className={classNames(styles.modalFieldLabel, styles.wider)}>Instalation Type</span>
                      <SelectStatic
                        placeholder="Select Type"
                        values={deviceValues.installationType as UITypes.Option[]}
                        options={[
                          {
                            value: "new",
                            label: "New",
                          },
                          {
                            value: "existing",
                            label: "Replacement",
                          },
                        ]}
                        onChange={(value) => setDeviceValues({ ...deviceValues, ...{ installationType: value } })}
                        errorMessage={error.field === "installationType" ? error.message : ""}
                      />
                    </div>
                    <div className={styles.modalFieldWrapper}>
                      <span className={classNames(styles.modalFieldLabel, styles.wider)}>Device location</span>
                      <SelectSubOptions
                        placeholder="Select device location"
                        data={rtLocations}
                        valueName="id"
                        labelName="name"
                        values={deviceValues.deviceLocation}
                        onChange={(value) => setDeviceValues({ ...deviceValues, ...{ deviceLocation: value } })}
                        errorMessage={error.field === "deviceLocation" ? error.message : ""}
                      />
                    </div>
                    <div className={styles.modalFieldWrapper}>
                      <span className={classNames(styles.modalFieldLabel, styles.wider)}>Membership</span>
                      <span className={styles.modalFieldValue}>{deviceValues.membership || "no"}</span>
                    </div>
                  </div>
                </div>
              </main>
            </div>
          </div>
        </ModalLayout>,
        document.body,
      )}
    </>
  )
}
