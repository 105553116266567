import styles from "./index.module.scss"
import { ContentWrapper } from "../../../components/common/ContentWrapper"
import { Table } from "../../../components/table/Table"
import { Pagination } from "../../../components/common/Pagination"
import { WidgetInventory } from "../../../components/widgets/WidgetInventory"
import { WidgetMonitoring } from "../../../components/widgets/WidgetMonitoring"
import { WidgetStatus } from "../../../components/widgets/WidgetStatus"
import { UITypes } from "../../../types"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import { showTransferModal, showMissingModalForContractor } from "../../../store/modalSlise"
import { useLazyGetDevicesQuery } from "../../../store/api/devicesApi"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import classNames from "classnames"
import { convertDevicesToDashboardTableRows } from "../../../utils/convertData"
import { setRequestParams, setSelectedDeviceIDs } from "../../../store/tempDataSlise"
import { FilterTags } from "../../../components/common/FilterTags"

// TABLE HEADER

const getTableHeaderCells = (): UITypes.TableHeaderCell[] => {
  return [
    {
      title: "",
      type: "checkbox",
    },
    {
      title: "Device S/N",
      type: "sort-search",
      name: "sn",
      size: "small",
    },
    {
      title: "Status",
      type: "select",
      name: "contractorDevicePhysicalStatus",
      size: "small",
    },
    {
      title: "Work status",
      type: "select",
      name: "contractorDeviceWorkStatus",
      size: "small",
    },
    {
      title: "Customer",
      type: "sort-search",
      name: "homeowner_name",
      size: "middle",
    },
    {
      title: "ZIP code",
      type: "sort-search",
      name: "zip_code",
      size: "small",
    },
    {
      title: "Activation date",
      type: "sort-date",
      name: "activation_date",
      size: "middle",
    },
    {
      title: "Address",
      type: "sort-search",
      name: "address",
    },
    {
      title: "HVAC",
      type: "select",
      size: "small",
      name: "hvac",
    },
    {
      title: "Alerts",
      type: "select",
      size: "small",
      name: "devicesAlerts",
    },
    {
      title: "Membership",
      type: "select",
      name: "devicesMembership",
      size: "small",
    },
    {
      title: "System type",
      type: "select",
      size: "small",
      name: "deviceSystemTypeStatus",
    },
    {
      title: "Accessories",
      type: "select",
      size: "small",
      name: "deviceAccessoriesStatus",
    },
  ]
}

export const Dashboard = () => {
  const [triggerGetDevices, { isFetching, data }] = useLazyGetDevicesQuery()
  const dispatch = useAppDispatch()
  const requestParams = useAppSelector((state) => state.tempData.requestParams)
  const selectedDeviceIDs = useAppSelector((state) => state.tempData.selectedDeviceIDs)
  const [isFirstRender, setFirstRender] = useState(true)
  const [isHeaderChecked, setHeaderChecked] = useState(false)

  const toggleAllDevices = () => {
    if (isHeaderChecked) {
      dispatch(setSelectedDeviceIDs([]))
      setHeaderChecked(false)
    } else {
      const result = data?.data?.map((item) => item?.id)
      result && dispatch(setSelectedDeviceIDs(result))
      setHeaderChecked(true)
    }
  }

  const onSelectDevice = (id?: number) => {
    if (selectedDeviceIDs?.some((item) => item === id)) {
      dispatch(setSelectedDeviceIDs(selectedDeviceIDs?.filter((item) => item !== id)))
    } else {
      dispatch(setSelectedDeviceIDs([...selectedDeviceIDs, ...[id]] as number[]))
    }
  }

  const onTransferClick = () => {
    dispatch(showTransferModal(true))
  }

  const onMissingClick = () => {
    dispatch(showMissingModalForContractor(true))
  }

  const sendRequest = async (params: UITypes.Params) => {
    try {
      const res: any = await triggerGetDevices(params)
      if (res?.error) {
        toast.error(res?.error?.data?.message)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (isFirstRender) {
      setFirstRender(false)
      return
    }
    sendRequest(requestParams === null ? {} : requestParams)
  }, [requestParams, isFirstRender])

  // componentWillUnmount hook
  useEffect(
    () => () => {
      dispatch(setRequestParams(null))
      dispatch(setSelectedDeviceIDs([]))
    },
    [],
  )

  return (
    <>
      <div className={classNames(styles.widgetsWrapper, styles.widgetsWrapperJustified)}>
        <WidgetInventory />
        <WidgetMonitoring />
        <WidgetStatus />
      </div>
      <ContentWrapper title="Devices">
        <nav className={styles.tableNavWrapper}>
          <div></div>
          <div className={styles.tableNavButtonsWrapper}>
            <div className={styles.statusButtonnWrapper}>
              {selectedDeviceIDs?.length ? (
                <>
                  <span className={classNames(styles.statusButton, styles.missing)} onClick={onMissingClick}>
                    Missing
                  </span>
                  <span className={classNames(styles.statusButton, styles.transfer)} onClick={onTransferClick}>
                    Transfer
                  </span>
                </>
              ) : (
                <>
                  <span className={classNames(styles.statusButton, styles.missing, styles.disabled)}>Missing</span>
                  <span className={classNames(styles.statusButton, styles.transfer, styles.disabled)}>Transfer</span>
                </>
              )}
            </div>
            {/* <ButtonNew className={styles.tableNavItem}>Export</ButtonNew> */}
          </div>
        </nav>
        <FilterTags />
        <Table
          headerCells={getTableHeaderCells()}
          bodyRows={convertDevicesToDashboardTableRows(data?.data)}
          isLoading={isFetching}
          onCheckboxChange={onSelectDevice}
          onHeaderCheckboxChange={toggleAllDevices}
          currentItemIDs={selectedDeviceIDs}
          isHeaderChecked={isHeaderChecked}
        />
        <Pagination meta={data?.meta} />
      </ContentWrapper>
    </>
  )
}
