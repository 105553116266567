import styles from "../../assets/scss/index.module.scss"
import { FormEvent, useState, useEffect } from "react"
import { createPortal } from "react-dom"
import { ModalHeader } from "../../components/common/ModalHeader"
import { ReactComponent as IconContractor } from "../../assets/svg/modal-icon-contractor.svg"
import { ModalLayout } from "../../layouts/ModalLayout"
import { InputNew } from "../../components/form/InputNew"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { showEditContractorModal3, showEditContractorModal2 } from "../../store/modalSlise"
import { UITypes } from "../../types"
import {
  useLazyEditContractorQuery,
  useLazyGetContractorsQuery,
  useLazyCheckServiceTitanQuery,
  useLazyGetSTMembershipsQuery,
} from "../../store/api/contractorsApi"
import { toast } from "react-toastify"
import { setCurrentZip, setContractor } from "../../store/tempDataSlise"
import { editContractor3Schema, onFormValidate, validationInitialState } from "../../utils/onValidate"
import { InputTenantID } from "../../components/form/InputTenantID"
import { SelectStatic } from "../../components/form/SelectStatic"
import { convertDataToOptions } from "../../utils/convertData"

const inputValuesInitialState: UITypes.Contractor = {
  stClientID: "",
  stClientSecret: "",
  stAppKey: "",
  stTenant: "",
}

interface Props {
  onCancel: () => void
}

export const EditContractorModal3 = ({ onCancel }: Props) => {
  const [triggerEditContractor, { isFetching }] = useLazyEditContractorQuery()
  const [triggerCheckServiceTitan, { isFetching: isSTFetching }] = useLazyCheckServiceTitanQuery()
  const [triggerGetSTMemberships, { isFetching: isSTMembershipsFetching, data: membershipsData }] =
    useLazyGetSTMembershipsQuery()
  const [triggerGetContractors] = useLazyGetContractorsQuery()
  const dispatch = useAppDispatch()
  const contractor = useAppSelector((state) => state.tempData.contractor)
  const requestParams = useAppSelector((state) => state.tempData.requestParams)
  const [inputValues, setInputValues] = useState(contractor!)
  const [error, setError] = useState(validationInitialState)
  const [tenantIDStatus, setTenantIDStatus] = useState<UITypes.TenantIDStautus>("check")
  const [memberships, setMemberships] = useState<UITypes.Option[]>([])

  const onInputChange = (event: FormEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = event.currentTarget

    setInputValues((prevState) => {
      return { ...prevState, [name]: type === "checkbox" ? checked : value }
    })
  }

  const onBack = () => {
    dispatch(setContractor({ ...contractor, ...inputValues }))
    dispatch(showEditContractorModal2(true))
    dispatch(showEditContractorModal3(false))
  }

  const prepareData = () => {
    if (!contractor) return

    const formData = new FormData()

    formData.append("name", contractor?.name!)
    formData.append("brand", contractor?.brand!)
    formData.append("ein", contractor?.ein!)
    formData.append("address1", contractor?.address1!)
    formData.append("address2", contractor?.address2!)
    formData.append("url", contractor.url!)
    formData.append("schedule", contractor.schedule!)
    formData.append("full_name", contractor.fullName!)
    formData.append("tel1", contractor.phone!)
    formData.append("email", contractor.email!)
    contractor?.state && formData.append("state", contractor?.state[0].value! as string)
    contractor?.city && formData.append("city", contractor?.city[0].value! as string)
    contractor?.zip && formData.append("zip", contractor?.zip[0].value! as string)
    contractor?.image && formData.append("image", contractor?.image)
    formData.append("is_enabled", contractor?.isDisable ? "0" : "1")
    formData.append("st_client_id", inputValues.stClientID!)
    formData.append("st_client_secret", inputValues.stClientSecret!)
    formData.append("st_app_key", inputValues.stAppKey!)
    formData.append("st_tenant", inputValues.stTenant!)
    formData.append(
      "memberships",
      JSON.stringify(memberships?.map((item) => ({ id: Number(item.value), name: item.label }))),
    )
    formData.append("has_st", "1")
    formData.append("is_st_checked", tenantIDStatus === "pass" ? "1" : "")
    contractor?.image && formData.append("image", contractor?.image)
    formData.append("is_custom", contractor.isCustom ? "1" : "0")
    formData.append("app_store_link", contractor.appStoreLink || "")
    formData.append("google_play_link", contractor.googlePlayLink || "")
    formData.append("phone_for_support", contractor.phoneForSupport ? "1" : "0")
    formData.append("phone_for_support_call", contractor.phoneForSupportCall || "")
    formData.append("phone_for_support_text", contractor.phoneForSupportText || "")

    return formData
  }

  const sendRequest = async () => {
    try {
      const res: any = await triggerEditContractor({
        data: prepareData() as any,
        id: contractor?.id!,
      })

      if (res?.error) {
        toast.error(res?.error?.data?.message)
      } else {
        toast.success("Contractor has been successfully edited!")
        setInputValues(inputValuesInitialState)
        dispatch(setCurrentZip(undefined))
        dispatch(setContractor(null))
        triggerGetContractors(requestParams === null ? {} : requestParams)
        onCancel()
      }
    } catch (error) {
      console.error(error)
    }
  }

  const onCancelClick = () => {
    onCancel()
    dispatch(setContractor(null))
  }

  const onSubmit = (event) => {
    event.preventDefault()

    if (onFormValidate(inputValues, editContractor3Schema, setError, true)) {
      dispatch(setContractor({ ...contractor, ...inputValues }))
      sendRequest()
    }
  }

  const tenantIDHandler = () => {
    if (tenantIDStatus === "error") {
      setTenantIDStatus("check")
    }
    if (tenantIDStatus === "check") {
      onTenantIDCheck()
    }
    if (tenantIDStatus === "pass") {
      setTenantIDStatus("check")
    }
  }

  const onTenantIDCheck = () => {
    if (onFormValidate(inputValues, editContractor3Schema, setError, true)) {
      sendTenantIDRequest()
    }
  }

  const sendTenantIDRequest = async () => {
    try {
      const res: any = await triggerCheckServiceTitan({
        client_id: inputValues.stClientID,
        client_secret: inputValues.stClientSecret,
        app_key: inputValues.stAppKey,
        tenant_id: inputValues.stTenant,
      })

      if (res?.error) {
        toast.error(res?.error?.data?.message)
        setTenantIDStatus("error")
      } else {
        setTenantIDStatus("pass")
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    contractor?.isSTChecked == `1` && setTenantIDStatus("pass")

    contractor?.memberships?.length &&
      setMemberships(
        contractor?.memberships?.map((item) => ({
          value: item?.id,
          label: item?.name,
        })),
      )
  }, [])

  useEffect(() => {
    if (tenantIDStatus === "pass") {
      const fetchData = async () => {
        try {
          await triggerGetSTMemberships({
            client_id: inputValues.stClientID,
            client_secret: inputValues.stClientSecret,
            app_key: inputValues.stAppKey,
            tenant_id: inputValues.stTenant,
          })
        } catch (error) {
          console.error(error)
        }
      }

      fetchData()
    }
  }, [tenantIDStatus])

  return (
    <>
      {createPortal(
        <ModalLayout
          width="450px"
          confirmButtonText="Save"
          confirmButtonType="submit"
          cancelButtonText="Back"
          onConfirm={onSubmit}
          onClose={onCancelClick}
          isDisabled={tenantIDStatus !== "pass"}
          isFetching={isFetching}
          onCancel={onBack}
        >
          <ModalHeader title="Edit Contractor" subtitle="Step 3/3">
            <IconContractor />
          </ModalHeader>
          <div className={styles.modalContent}>
            <InputNew
              label="Client ID"
              placeholder="Enter client ID"
              name="stClientID"
              value={inputValues.stClientID || ""}
              onChange={onInputChange}
              errorMessage={error.field === "stClientID" ? error.message : ""}
            />
            <InputNew
              label="Client Secret"
              placeholder="Enter client secret"
              name="stClientSecret"
              value={inputValues.stClientSecret || ""}
              onChange={onInputChange}
              errorMessage={error.field === "stClientSecret" ? error.message : ""}
            />
            <InputNew
              label="API Key"
              placeholder="Enter API Key"
              name="stAppKey"
              value={inputValues.stAppKey || ""}
              onChange={onInputChange}
              errorMessage={error.field === "stAppKey" ? error.message : ""}
            />
            <InputTenantID
              status={isSTFetching ? "loading" : tenantIDStatus}
              label="Tenant ID"
              placeholder="Tenant ID"
              name="stTenant"
              value={inputValues.stTenant || ""}
              onChange={onInputChange}
              buttonHandler={tenantIDHandler}
              errorMessage={error.field === "stTenant" ? error.message : ""}
            />
            {tenantIDStatus === "pass" && (
              <SelectStatic
                label="Memberships"
                placeholder="Select Membership"
                multi={true}
                values={memberships}
                isLoading={isSTMembershipsFetching}
                options={convertDataToOptions(membershipsData, "id", "name")}
                onChange={setMemberships}
              />
            )}
          </div>
        </ModalLayout>,
        document.body,
      )}
    </>
  )
}
