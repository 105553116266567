import { useState, useEffect } from "react"
import { setRequestParams } from "../../../store/tempDataSlise"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import ReactSelect, { components } from "react-select"
import { ReactComponent as IconSelect } from "../../../assets/svg/icon-select.svg"
import { ReactComponent as IconTableCross } from "../../../assets/svg/icon-table-cross.svg"
import classNames from "classnames"
import { UITypes } from "../../../types"
import styles from "./index.module.scss"
import "./select.scss"

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <span
        className={classNames(styles.iconArrow, {
          [styles.open]: props?.selectProps?.menuIsOpen,
        })}
      >
        <IconSelect />
      </span>
    </components.DropdownIndicator>
  )
}

const ClearIndicator = (props) => {
  const { innerProps } = props
  return (
    <div {...innerProps} className={styles.clearIcon}>
      <IconTableCross />
    </div>
  )
}

interface Props {
  placeholder?: string
  name?: string
}

export const TableSelect = ({ name, placeholder }: Props) => {
  const dispatch = useAppDispatch()
  const requestParams = useAppSelector((state) => state.tempData.requestParams)
  const [value, setValue] = useState<UITypes.Option | undefined>(undefined)

  useEffect(() => {
    if (name === "contractorStatus") {
      const result = contractorStatusOptions.filter((item) => item.value === requestParams?.isEnabled?.value)
      setValue(result[0])
    }

    if (name === "alertStatus") {
      const result = alertStatusOptions.filter((item) => item.value === requestParams?.status?.value)
      setValue(result[0])
    }

    if (requestParams?.type !== undefined && name === "alertType") {
      const result = alertTypeOptions.filter((item) => item.value === requestParams?.type?.value)
      setValue(result[0])
    }

    if (requestParams?.status !== undefined && name === "orderStatus") {
      const result = orderStatusOptions.filter((item) => item.value === requestParams?.status?.value)
      setValue(result[0])
    }

    if (requestParams?.status !== undefined && name === "warehouseManagerOrderStatus") {
      const result = warehouseManagerOrderStatusOptions.filter((item) => item.value === requestParams?.status?.value)
      setValue(result[0])
    }

    if (requestParams?.phisical_status !== undefined && name === "warehouseManagerDeviceStatus") {
      const result = warehouseManagerDeviceStatusOptions.filter(
        (item) => item.value === requestParams?.phisical_status?.value,
      )
      setValue(result[0])
    }

    if (requestParams?.accessories !== undefined && name === "deviceAccessoriesStatus") {
      const result = deviceAccessoriesOptions.filter((item) => item.value === requestParams?.accessories?.value)
      setValue(result[0])
    }

    if (requestParams?.system_type !== undefined && name === "deviceSystemTypeStatus") {
      const result = deviceSystemTypeOptions.filter((item) => item.value === requestParams?.system_type?.value)
      setValue(result[0])
    }

    if (requestParams?.role !== undefined && name === "userRole") {
      const result = userRoleOptions.filter((item) => item.value === requestParams?.role?.value)
      setValue(result[0])
    }

    if (requestParams?.phisical_status !== undefined && name === "vendorDevicePhysicalStatus") {
      const result = vendorDevicePhysicalStatusOptions.filter(
        (item) => item.value === requestParams?.phisical_status?.value,
      )
      setValue(result[0])
    }

    if (requestParams?.phisical_status !== undefined && name === "contractorDevicePhysicalStatus") {
      const result = contractorDevicePhysicalStatusOptions.filter(
        (item) => item.value === requestParams?.phisical_status?.value,
      )
      setValue(result[0])
    }

    if (requestParams?.work_status !== undefined && name === "vendorDeviceWorkStatus") {
      const result = deviceWorkStatusOptions.filter((item) => item.value === requestParams?.work_status?.value)
      setValue(result[0])
    }

    if (requestParams?.work_status !== undefined && name === "contractorDeviceWorkStatus") {
      const result = deviceWorkStatusOptions.filter((item) => item.value === requestParams?.work_status?.value)
      setValue(result[0])
    }

    if (requestParams?.red_flag_type !== undefined && name === "vendorDeviceRedFlagType") {
      const result = vendorDeviceRedFlagTypeOptions.filter((item) => item.value === requestParams?.red_flag_type?.value)
      setValue(result[0])
    }

    if (requestParams?.red_flag !== undefined && name === "vendorDeviceRedFlag") {
      const result = vendorDeviceRedFlagOptions.filter((item) => item.value === requestParams?.red_flag?.value)
      setValue(result[0])
    }

    if (requestParams?.alerts !== undefined && name === "devicesAlerts") {
      const result = devicesAlertsOptions.filter((item) => item.value === requestParams?.alerts?.value)
      setValue(result[0])
    }

    if (requestParams?.membership !== undefined && name === "devicesMembership") {
      const result = devicesMembershipOptions.filter((item) => item.value === requestParams?.membership?.value)
      setValue(result[0])
    }
    if (requestParams?.installation_type !== undefined && name === "hvac") {
      const result = hvacOptions.filter((item) => item.value === requestParams?.installation_type?.value)
      setValue(result[0])
    }
  }, [requestParams])

  const onChange = (data: UITypes.Option) => {
    let paramName = ""

    if (!name) return

    switch (name) {
      case "alertStatus":
        paramName = "status"
        break

      case "alertType":
        paramName = "type"
        break

      case "contractorStatus":
        paramName = "isEnabled"
        break

      case "orderStatus":
        paramName = "status"
        break

      case "warehouseManagerOrderStatus":
        paramName = "status"
        break

      case "warehouseManagerDeviceStatus":
        paramName = "phisical_status"
        break

      case "deviceSystemTypeStatus":
        paramName = "system_type"
        break

      case "deviceAccessoriesStatus":
        paramName = "accessories"
        break

      case "userRole":
        paramName = "role"
        break

      case "vendorDevicePhysicalStatus":
        paramName = "phisical_status"
        break

      case "contractorDevicePhysicalStatus":
        paramName = "phisical_status"
        break

      case "devicesAlerts":
        paramName = "alerts"
        break

      case "devicesMembership":
        paramName = "membership"
        break

      case "vendorDeviceWorkStatus":
      case "contractorDeviceWorkStatus":
        paramName = "work_status"
        break

      case "vendorDeviceRedFlagType":
        paramName = "red_flag_type"
        break

      case "vendorDeviceRedFlag":
        paramName = "red_flag"
        break

      case "hvac":
        paramName = "installation_type"
        break

      default:
        paramName = name
        break
    }

    dispatch(
      setRequestParams({
        [paramName]: data?.value
          ? ({
              value: data?.value,
              label: placeholder,
              type: "select",
            } as UITypes.Param)
          : null,
      }),
    )
  }

  const getCurrentOptions = () => {
    if (name === "alertStatus") {
      return alertStatusOptions
    }

    if (name === "alertType") {
      return alertTypeOptions
    }

    if (name === "contractorStatus") {
      return contractorStatusOptions
    }

    if (name === "warehouseManagerOrderStatus") {
      return warehouseManagerOrderStatusOptions
    }

    if (name === "warehouseManagerDeviceStatus") {
      return warehouseManagerDeviceStatusOptions
    }

    if (name === "deviceSystemTypeStatus") {
      return deviceSystemTypeOptions
    }

    if (name === "deviceAccessoriesStatus") {
      return deviceAccessoriesOptions
    }

    if (name === "userRole") {
      return userRoleOptions
    }

    if (name === "orderStatus") {
      return orderStatusOptions
    }

    if (name === "vendorDevicePhysicalStatus") {
      return vendorDevicePhysicalStatusOptions
    }

    if (name === "contractorDevicePhysicalStatus") {
      return contractorDevicePhysicalStatusOptions
    }

    if (name === "devicesAlerts") {
      return devicesAlertsOptions
    }

    if (name === "devicesMembership") {
      return devicesMembershipOptions
    }

    if (name === "vendorDeviceWorkStatus" || name === "contractorDeviceWorkStatus") {
      return deviceWorkStatusOptions
    }

    if (name === "vendorDeviceRedFlagType") {
      return vendorDeviceRedFlagTypeOptions
    }

    if (name === "hvac") {
      return hvacOptions
    }

    if (name === "vendorDeviceRedFlag") {
      return vendorDeviceRedFlagOptions
    } else return []
  }

  return (
    <div className={classNames(styles.field)}>
      <ReactSelect
        // menuIsOpen
        // isMulti
        options={getCurrentOptions()}
        classNamePrefix="filter"
        value={value || null}
        isClearable={true}
        hideSelectedOptions={true}
        className="custom"
        name={name}
        placeholder={placeholder}
        onChange={onChange as any}
        components={{
          DropdownIndicator,
          ClearIndicator,
        }}
      />
    </div>
  )
}

const contractorStatusOptions = [
  {
    label: "Enabled",
    value: "1",
  },
  {
    label: "Disabled",
    value: "0",
  },
]

const alertStatusOptions = [
  {
    label: "Active",
    value: "active",
  },
  {
    label: "Acknowledged",
    value: "acknowledged",
  },
  {
    label: "Frozen",
    value: "frozen",
  },
]

const alertTypeOptions = [
  {
    label: "Air quality",
    value: "air_quality",
  },
  {
    label: "Offline",
    value: "offline",
  },
  {
    label: "Temperature",
    value: "temperature",
  },
  {
    label: "Humidity sensor malfunction",
    value: "humidity_sensor_malfunction",
  },
  {
    label: "Fan malfunction",
    value: "fan_malfunction",
  },
  {
    label: "Temperature sensor malfunction",
    value: "temperature_sensor_malfunction",
  },
  {
    label: "Ambient sensor malfunction",
    value: "ambient_sensor_malfunction",
  },
]

const orderStatusOptions = [
  {
    label: "New",
    value: "new",
    // value: 1,
  },
  {
    label: "Pending",
    value: "pending",
    // value: 2,
  },
  {
    label: "Denied",
    value: "denied",
    // value: 3,
  },
  {
    label: "Confirmed",
    value: "confirmed",
    // value: 4,
  },
  {
    label: "Packed",
    value: "packed",
    // value: 5,
  },
  {
    label: "Shipping",
    value: "shipping",
    // value: 6,
  },
]

const warehouseManagerOrderStatusOptions = [
  {
    label: "Confirmed",
    value: "confirmed",
  },
  {
    label: "Packed",
    value: "packed",
  },
  {
    label: "Shipping",
    value: "shipping",
  },
]

const warehouseManagerDeviceStatusOptions = [
  {
    label: "In Stock",
    value: "stocked",
  },
  {
    label: "Missing",
    value: "missing",
  },
  {
    label: "Warranty",
    value: "warranty",
  },
  {
    label: "Damaged",
    value: "damaged",
  },
  {
    label: "Packed",
    value: "packed",
  },
]

const deviceSystemTypeOptions = [
  {
    label: "Traditional",
    value: "traditional",
  },
  {
    label: "Heat Pump",
    value: "heat_pump",
  },
  {
    label: "Heat only",
    value: "heating",
  },
  {
    label: "Cool only",
    value: "cooling",
  },
]

const deviceAccessoriesOptions = [
  {
    label: "Humidifier",
    value: "humidifier",
  },
  {
    label: "Dehumidifier",
    value: "dehumidifier",
  },
]

const userRoleOptions = [
  {
    label: "Vendor Admin",
    value: "VENDOR_ADMIN",
  },
  {
    label: "Vendor Sales Admin",
    value: "VENDOR_SALES_MANAGER",
  },
  {
    label: "Warehouse Manager",
    value: "WAREHOUSE_MANAGER",
  },
]

const vendorDevicePhysicalStatusOptions = [
  {
    label: "In Stock",
    value: "stocked",
  },
  {
    label: "In Inventory",
    value: "inventory",
  },
  {
    label: "Installed",
    value: "installed",
  },
  {
    label: "Missing",
    value: "missing",
  },
  {
    label: "Production",
    value: "production",
  },
  {
    label: "Warranty",
    value: "warranty",
  },
  {
    label: "Damaged",
    value: "damaged",
  },
  {
    label: "Packed",
    value: "packed",
  },
  {
    label: "Transferring",
    value: "transferring",
  },
]

const deviceWorkStatusOptions = [
  {
    label: "Online",
    value: "online",
  },
  {
    label: "Offline",
    value: "offline",
  },
]

const vendorDeviceRedFlagOptions = [
  {
    label: "Yes",
    value: "1",
  },
  {
    label: "No",
    value: "0",
  },
]

const devicesAlertsOptions = [
  {
    label: "Yes",
    value: "yes",
  },
  {
    label: "No",
    value: "no",
  },
]

const devicesMembershipOptions = [
  {
    label: "Yes",
    value: "yes",
  },
  {
    label: "No",
    value: "no",
  },
]

const vendorDeviceRedFlagTypeOptions = [
  {
    label: "In Inventory & online",
    value: "In Inventory & online",
  },
  {
    label: "In Stock & online",
    value: "In Stock & online",
  },
  {
    label: "Warranty & online",
    value: "Warranty & online",
  },
  {
    label: "Missing & online",
    value: "Missing & online",
  },
  {
    label: "Damaged & online",
    value: "Damaged & online",
  },
  {
    label: "Production & online",
    value: "Production & online",
  },
  {
    label: "Alert over 2 days",
    value: "Alert over 2 days",
  },
  {
    label: "Offline over 7 days",
    value: "Offline over 7 days",
  },
  {
    label: "Installed without ST",
    value: "Installed without ST",
  },
  {
    label: "Installed by other tech",
    value: "Installed by other tech",
  },
  {
    label: "Wrong location",
    value: "Wrong location",
  },
]

const contractorDevicePhysicalStatusOptions = [
  {
    label: "In Inventory",
    value: "inventory",
  },
  {
    label: "Installed",
    value: "installed",
  },
  {
    label: "Missing",
    value: "missing",
  },
  {
    label: "Warranty",
    value: "warranty",
  },
  {
    label: "Damaged",
    value: "damaged",
  },
  {
    label: "Transferring",
    value: "transferring",
  },
  {
    label: "Transferred",
    value: "transferred",
  },
]

const hvacOptions = [
  {
    label: "New",
    value: "new",
  },
  {
    label: "Existing",
    value: "existing",
  },
]
