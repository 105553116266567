import { WidgetLayout } from "../../../layouts/WidgetLayout"
import styles from "./index.module.scss"
import { BarChart } from "../../common/BarChart"
import classNames from "classnames"
import { useState, useEffect } from "react"
// import { Spinner } from "../../common/Spinner"

const initialState = {
  activeContractors: 0,
  inActiveContractors: 0,
  widthMemberships: 0,
  widthoutMemberships: 0,
}

export const WidgetSTAndMemberships = () => {
  const [widgetData, setWidgetData] = useState(initialState)

  useEffect(() => {
    setWidgetData({
      activeContractors: 8,
      inActiveContractors: 8,
      widthMemberships: 8,
      widthoutMemberships: 8,
    })
  }, [])

  return (
    <WidgetLayout title="ServiceTitan" className={styles.widgetWrapper}>
      <div
        className={classNames(styles.widgetContent, {
          // [styles.disabled]: isFetching,
        })}
      >
        <div className={styles.chartItemWrapper}>
          <div className={styles.chartValuesWrapper}>
            <span className={classNames(styles.chartValuesTitle, styles.dark)}>
              Active Contractor with ServiceTitan
            </span>
            <span className={styles.chartValuesTitle}>8 (50%)</span>
          </div>
          <BarChart
            className={styles.chart}
            items={[
              {
                value: widgetData.activeContractors,
                color: "#475569",
              },
              {
                value: widgetData.inActiveContractors,
                color: "#E2E8F0",
              },
            ]}
          />
        </div>
        {/* <div className={styles.chartItemWrapper}>
          <div className={styles.chartValuesWrapper}>
            <span className={classNames(styles.chartValuesTitle, styles.blue)}>Devices with Memberships</span>
            <span className={styles.chartValuesTitle}>8 (50%)</span>
          </div>
          <BarChart
            className={styles.chart}
            items={[
              {
                value: widgetData.activeContractors,
                color: "#43E0F8",
              },
              {
                value: widgetData.inActiveContractors,
                color: "#E2E8F0",
              },
            ]}
          />
        </div> */}
        {/* {isFetching && (
          <div className={styles.widgetLoader}>
            <Spinner />
          </div>
        )} */}
      </div>
    </WidgetLayout>
  )
}
