import { createApi } from "@reduxjs/toolkit/query/react"
import { ApiTypes, UITypes } from "../../types"
import { baseQueryWithAuth } from "../../utils/api"
import { prepareQueryParams } from "../../utils/prepareQueryParams"

export const vendorApi = createApi({
  reducerPath: "vendor",
  tagTypes: ["vendor"],
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({
    getVendorOrders: builder.query<ApiTypes.Res.VSManagerOrders, UITypes.Params>({
      query: (arg) => {
        return {
          url: `/vendor/orders`,
          method: "GET",
          params: prepareQueryParams(arg),
          headers: { Accept: "application/json" },
        }
      },
    }),
    getVendorFulfilledOrders: builder.query<ApiTypes.Res.VSManagerOrders, UITypes.Params>({
      query: (arg) => {
        return {
          url: `/vendor/orders/fulfilled`,
          method: "GET",
          params: prepareQueryParams(arg),
          headers: { Accept: "application/json" },
        }
      },
    }),
    createOrder: builder.query<ApiTypes.Res.VSManagerOrders, ApiTypes.Req.CreateVendorOrder>({
      query: (body) => ({
        url: `/vendor/orders`,
        method: "POST",
        body,
        headers: { Accept: "application/json" },
      }),
    }),
    editOrder: builder.query<ApiTypes.Res.VSManagerOrders, { data: ApiTypes.Req.EditVendorOrder; id: number }>({
      query: (body) => ({
        url: `/vendor/orders/${body.id}/`,
        method: "POST",
        body: body.data,
      }),
    }),
    getWarehouseWidgetInfo: builder.query<ApiTypes.Res.VendorWarehouseWidgetInfo, UITypes.Params>({
      query: (arg) => {
        return {
          url: `/vendor/widgets/warehouses`,
          method: "GET",
          params: prepareQueryParams(arg),
          headers: { Accept: "application/json" },
        }
      },
    }),
    getOrderStatusWidget: builder.query<ApiTypes.Res.VendorOrderStatus, void>({
      query: () => {
        return {
          url: `/vendor/widgets/orders`,
          method: "GET",
          headers: { Accept: "application/json" },
        }
      },
    }),
  }),
})

export const {
  useCreateOrderQuery,
  useLazyGetVendorOrdersQuery,
  useLazyGetVendorFulfilledOrdersQuery,
  useLazyCreateOrderQuery,
  useLazyGetWarehouseWidgetInfoQuery,
  useLazyGetOrderStatusWidgetQuery,
  useLazyEditOrderQuery,
} = vendorApi
