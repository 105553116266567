import styles from "./index.module.scss"
import { ButtonNew } from "../ButtonNew"
import { v4 as uuidv4 } from "uuid"
import { Textarea } from "../Textarea"
import { Tooltip } from "../../common/Tooltip"
import classNames from "classnames"
import { useState, FormEvent, useEffect } from "react"
import { validationInitialState, onFormValidate, createMessageTemplateSchema } from "../../../utils/onValidate"
import { UITypes, ApiTypes } from "../../../types"
import { Spinner } from "../../common/Spinner"

const frequencies: {
  label: string
  value: ApiTypes.Model.MessageTemplateFrequency
}[] = [
  {
    label: "Does not repeat",
    value: "never",
  },
  {
    label: "1 month",
    value: "1_month",
  },
  {
    label: "6 months",
    value: "6_months",
  },
  {
    label: "12 months",
    value: "12_months",
  },
]

const inputValuesInitialState: UITypes.MessageTemplate = {
  title: "",
  message: "",
  frequency: "never",
}

interface Props {
  onSave?: (data: UITypes.MessageTemplate) => void
  isLoading?: boolean
  editableTemplate?: UITypes.MessageTemplate
}

export const MessageForm = ({ onSave, isLoading, editableTemplate }: Props) => {
  const [inputValues, setInputValues] = useState(inputValuesInitialState)
  const [error, setError] = useState(validationInitialState)

  const onInputChange = (event: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.currentTarget

    setInputValues((prevState) => {
      return { ...prevState, [name]: value }
    })
  }

  const setFrequency = (value: ApiTypes.Model.MessageTemplateFrequency) => {
    setInputValues({ ...inputValues, ...{ frequency: value } })
  }

  const onSubmit = () => {
    const data = {
      frequency: inputValues.frequency,
      title: inputValues.title,
      message: inputValues.message,
    }

    if (onFormValidate(data, createMessageTemplateSchema, setError)) {
      onSave && onSave(data)
      setFrequency("never")
      setInputValues(inputValuesInitialState)
    }
  }

  useEffect(() => {
    if (editableTemplate) {
      setInputValues(editableTemplate)
    }
  }, [editableTemplate])

  return (
    <form className={styles.messageFormWrapper} onSubmit={onSubmit}>
      <input
        type="text"
        className={classNames(styles.titleImput, { [styles.error]: error.field === "title" })}
        name="title"
        placeholder="Type a title..."
        value={inputValues.title}
        onChange={onInputChange}
      />
      {error.field === "title" && <div className={styles.errorMessage}>Title is required</div>}
      <div className={styles.textareaWrapper}>
        <span className={styles.textareaLabel}>Message</span>
        <Textarea
          placeholder="Type your text..."
          name="message"
          value={inputValues.message}
          onChange={onInputChange}
          errorMessage={error.field === "message" ? error.message : ""}
        />
      </div>
      <Tooltip id={uuidv4()} text="Repeat every" className={styles.tooltip}>
        Choose how often you want the event to repeat.
      </Tooltip>
      <div className={styles.repeatButtonsWrapper}>
        {frequencies.map((item) => (
          <span
            key={uuidv4()}
            onClick={() => setFrequency(item.value)}
            className={classNames(styles.repeatButtons, {
              [styles.active]: inputValues.frequency === item.value,
            })}
          >
            {item.label}
          </span>
        ))}
      </div>
      <div className={styles.submitButtonWrapper}>
        <ButtonNew disabled={isLoading} onClick={onSubmit} color="blue">
          {isLoading ? <Spinner /> : "Save"}
        </ButtonNew>
      </div>
    </form>
  )
}
