import styles from "./index.module.scss"
import { useEffect, useState } from "react"
import { ContentWrapper } from "../../../components/common/ContentWrapper"
import { Table } from "../../../components/table/Table"
import { Pagination } from "../../../components/common/Pagination"
import { ButtonNew } from "../../../components/form/ButtonNew"
import { CheckboxNew } from "../../../components/form/CheckboxNew"
import { useLazyGetVendorFulfilledOrdersQuery, useLazyGetVendorOrdersQuery } from "../../../store/api/vendorApi"
import { UITypes } from "../../../types"
import { toast } from "react-toastify"
import { convertVSMOrdersToTableRows } from "../../../utils/convertData"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import {
  showDeleteOrderModalForVendor,
  showNewVendorOrderModal,
  showOrderFulfillmentModal,
  showEditVendorOrderModal,
} from "../../../store/modalSlise"
import { setRequestParams, setSelectedVSMOrder, setCurrentOrder } from "../../../store/tempDataSlise"
import { WidgetWarehouseInformation } from "../../../components/widgets/WidgetWarehouseInformation"
import { WidgetVendorOrderStatus } from "../../../components/widgets/WidgetVendorOrderStatus"
import { FilterTags } from "../../../components/common/FilterTags"

// TABLE HEADER
const tableHeaderCells: UITypes.TableHeaderCell[] = [
  {
    title: "",
  },
  {
    title: "Order number",
    type: "sort-search",
    name: "id",
  },
  {
    title: "Status",
    type: "select",
    name: "orderStatus",
  },
  {
    title: "Company name",
    type: "sort-search",
    name: "company_name",
  },
  {
    title: "Creation Date",
    type: "sort-date",
    name: "create_date",
  },
  {
    title: "Quantity",
    type: "sort-search",
    name: "quantity",
  },
  {
    title: "Warehouse",
    type: "sort-search",
    name: "warehouse",
  },
  {
    title: "Phone number",
    type: "sort-search",
    name: "phone_number",
  },
  {
    title: "Email",
    type: "sort-search",
    name: "email",
  },
  {
    title: "Status Updated",
    type: "sort-search",
    name: "status_updated",
  },
]

export const Orders = () => {
  const dispatch = useAppDispatch()
  const [triggerGetOrders, { isFetching, data }] = useLazyGetVendorOrdersQuery()
  const [triggerGetFulfilledOrder, { isFetching: isFetchingFulfilled, data: dataFulfilled }] =
    useLazyGetVendorFulfilledOrdersQuery()
  const [selectFulfilled, setSelectFulfilled] = useState<boolean>(false)
  const selectedVSMOrder = useAppSelector((state) => state.tempData.selectedVSMOrder)
  const requestParams = useAppSelector((state) => state.tempData.requestParams)
  const [isFirstRender, setFirstRender] = useState(true)

  const onSetCurrentOrder = (id?: number) => {
    if (!selectFulfilled) {
      const ordersList = data?.data

      if (!id || !ordersList?.length) {
        dispatch(setSelectedVSMOrder(undefined))
        dispatch(setCurrentOrder(undefined))
      } else {
        const selectedVSMOrder = ordersList.filter((item) => item.id === id)[0]
        dispatch(setSelectedVSMOrder(selectedVSMOrder))
        dispatch(setCurrentOrder(selectedVSMOrder))
      }
    }
  }

  const onEditClick = (id: number) => {
    if (!selectFulfilled) {
      onSetCurrentOrder(id)
      dispatch(showEditVendorOrderModal(true))
    }
  }

  const onDeleteClick = (id: number) => {
    if (!selectFulfilled) {
      onSetCurrentOrder(id)
      dispatch(showDeleteOrderModalForVendor(true))
    }
  }

  const onNewClick = () => {
    dispatch(showNewVendorOrderModal(true))
  }

  const onShowFulfill = (event: React.FormEvent<HTMLInputElement>) => {
    setSelectFulfilled(event.currentTarget.checked)
  }

  const onFulfillClick = () => {
    if (!checkFulfillAbility()) return false
    dispatch(showOrderFulfillmentModal(true))
  }

  const onCheckboxChange = (id: number) => {
    onSetCurrentOrder(id === selectedVSMOrder?.id ? undefined : id)
  }

  const checkFulfillAbility = () => {
    return selectedVSMOrder?.id && selectedVSMOrder?.status === "shipping"
  }

  const sendRequest = async (params: UITypes.Params) => {
    try {
      const res: any = selectFulfilled ? await triggerGetFulfilledOrder(params) : await triggerGetOrders(params)
      if (res?.error) {
        toast.error(res?.error?.data?.message)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (isFirstRender) {
      setFirstRender(false)
      return
    }
    sendRequest(requestParams === null ? {} : requestParams)
  }, [requestParams, selectFulfilled, isFirstRender])

  useEffect(
    () => () => {
      dispatch(setRequestParams(null))
    },
    [],
  )

  // componentWillUnmount hook
  useEffect(
    () => () => {
      dispatch(setRequestParams(null))
    },
    [],
  )

  return (
    <>
      <div className={styles.widgetsWrapper}>
        <WidgetWarehouseInformation />
        <WidgetVendorOrderStatus />
      </div>
      <ContentWrapper title="Orders history">
        <nav className={styles.tableNavWrapper}>
          <div>
            <ButtonNew onClick={onNewClick} color="blue">
              New order
            </ButtonNew>
          </div>
          <div className={styles.tableNavButtonsWrapper}>
            <ButtonNew
              disabled={!checkFulfillAbility()}
              onClick={onFulfillClick}
              color={!checkFulfillAbility() ? "grey" : "blue"}
            >
              Fulfill
            </ButtonNew>
            <CheckboxNew onChange={onShowFulfill} className={styles.tableNavItem} isReverse={true} id="fulfilled">
              Show fulfilled
            </CheckboxNew>
            {/* <ButtonNew className={styles.tableNavItem}>Export</ButtonNew> */}
          </div>
        </nav>
        <FilterTags
          clearAdditionalTags={() => dispatch(setSelectedVSMOrder(undefined))}
          additionalTags={
            selectedVSMOrder
              ? [
                  {
                    label: "Order number",
                    values: [selectedVSMOrder.id],
                  },
                ]
              : []
          }
        />
        <Table
          tableName="vendor-orders"
          headerCells={tableHeaderCells}
          bodyRows={convertVSMOrdersToTableRows(selectFulfilled ? dataFulfilled?.data : data?.data)}
          isLoading={isFetching || isFetchingFulfilled}
          currentItemID={selectedVSMOrder?.id}
          onCheckboxChange={onCheckboxChange}
          onEditClick={onEditClick}
          onDeleteClick={onDeleteClick}
        />
        <Pagination meta={selectFulfilled ? dataFulfilled?.meta : data?.meta} />
      </ContentWrapper>
    </>
  )
}
