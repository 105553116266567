import { ApiTypes, UITypes } from "../types"
import { transformText } from "./transformText"
import { transformString } from "./transformString"
import dayjs from "dayjs"
import { v4 as uuidv4 } from "uuid"

export const convertStatusIDToOrderStatus = (id: number): UITypes.OrderStatus => {
  /*
    CASE New = 1;
    CASE Pending = 2;
    CASE Denied = 3;
    CASE Confirmed = 4;
    CASE Packed = 5;
    CASE Shipping = 6;
    CASE Archived (Fulfilled) = 7;
  */

  switch (id) {
    case 1:
      return "new"
    case 2:
      return "pending"
    case 3:
      return "denied"
    case 4:
      return "confirmed"
    case 5:
      return "packed"
    case 6:
      return "shipping"
    case 7:
      return "fulfilled"
    default:
      return ""
  }
}

export const convertStatusesToOptions = (data?: ApiTypes.Res.Status[]): UITypes.Option[] => {
  if (!data?.length) return []

  return data.map((item) => {
    return {
      value: String(item.id),
      label: item.name,
    }
  })
}

export const convertDataToOptions = (data: any, valueName: string, labelName: string): UITypes.Option[] => {
  if (!data?.data?.length) return []

  return data.data.map((item) => {
    return {
      value: item && item[valueName],
      label: item && item[labelName],
    }
  })
}

// TABLES

export const convertTechniciansToTableRows = (data?: ApiTypes.Model.Technician[]): UITypes.TableBodyRow[] => {
  if (!data?.length) return []

  return data.map(
    ({ id, full_name, username, installed_devices }) =>
      ({
        cells: [
          { value: id }, // Technician (ID number)
          { value: full_name, size: "large" }, // Full Name
          { value: username }, // Username
          { value: installed_devices }, // Installed Devices
        ],
        id,
      }) as UITypes.TableBodyRow,
  )
}

export const convertVSMOrdersToTableRows = (data?: ApiTypes.Model.VSManagerOrder[]): UITypes.TableBodyRow[] => {
  if (!data?.length) return []
  return data.map(
    ({ id, create_date, quantity, status, email, phone_number, brand_name, company_name, warehouse, status_updated }) =>
      ({
        cells: [
          { type: "checkbox" }, // Checkbox
          { value: id }, // Order ID
          { value: status, type: "status" }, // Status
          { value: company_name }, // Company Name
          { value: create_date ? dayjs(create_date).format("YYYY-MM-DD") : null }, // Creation Date
          { value: quantity }, // Quantity
          { value: warehouse }, // Warehouse
          { value: phone_number }, // Phone Number
          { value: email }, // Email
          { value: status_updated ? dayjs(status_updated).format("YYYY-MM-DD") : null }, //
        ],
        id,
        status,
        isEnable: status === "new" ? true : false,
      }) as UITypes.TableBodyRow,
  )
}

export const convertOrdersToTableRows = (data?: ApiTypes.Model.Order[]): UITypes.TableBodyRow[] => {
  if (!data?.length) return []

  return data.map(
    ({ id, create_date, quantity, warehouse, status }) =>
      ({
        cells: [
          { type: "checkbox" }, // Checkbox
          { value: id, size: "middle" }, // Order ID
          { value: dayjs(create_date).format("YYYY-MM-DD"), size: "middle" }, // Creation Date
          { value: quantity, size: "middle" }, // Quantity
          { value: status, type: "status" }, // Status
          { value: warehouse }, // Warehouse
        ],
        id,
        status: status,
      }) as UITypes.TableBodyRow,
  )
}

export const convertClientsToTableRows = (data?: ApiTypes.Model.Client[]): UITypes.TableBodyRow[] => {
  if (!data?.length) return []

  return data.map(
    ({ id, full_name, devices, email, phone_number, address1, zip, city, state, membership }) =>
      ({
        cells: [
          { value: email }, // Email
          {
            value: devices,
            size: "small",
          }, // Devices
          { value: phone_number }, // Phone
          { value: full_name }, // Full Name
          {
            value: id,
            size: "middle",
          }, // Customer ID
          {
            value: membership,
            size: "small",
          }, // Membership
        ],
        id,
        isEnable: devices ? true : false,
      }) as UITypes.TableBodyRow,
  )
}

export const convertContractorsToTableRows = (data?: ApiTypes.Model.Contractor[]): UITypes.TableBodyRow[] => {
  if (!data?.length) return []

  return data.map(
    ({ id, name, brand, purchased_devices, installed_devices, inventory_devices, is_enabled, state }) =>
      ({
        cells: [
          { value: name }, // Company name
          { value: state?.name }, // State
          { value: brand }, // Brand Name
          { value: purchased_devices }, // Purchased Devices
          { value: installed_devices }, // Installed Devices
          { value: inventory_devices }, // Inventory
          {
            value: is_enabled,
            type: "boolean",
          }, // Status
        ],
        id: Number(id),
        isEnabled: is_enabled,
      }) as UITypes.TableBodyRow,
  )
}

export const convertAlertsToTableRows = (data?: ApiTypes.Model.Alert[]): UITypes.TableBodyRow[] => {
  if (!data?.length) return []

  return data.map(
    ({ id, sn, address1, city, state, date, status, type }) =>
      ({
        cells: [
          { type: "checkbox" }, // Checkbox
          { value: transformText(type) }, // Alerts
          { value: date }, // Date
          { value: sn }, // Device S/N
          {
            value: status,
            type: "alert-status",
          }, // Status
          { value: address1 }, // Address
          { value: city }, // City
          { value: state }, // State
        ],
        id,
      }) as UITypes.TableBodyRow,
  )
}

export const convertMessageTemplatesToTableRows = (data?: ApiTypes.Model.MessageTemplate[]): UITypes.TableBodyRow[] => {
  if (!data?.length) return []

  return data.map(
    ({ id, title, body, created_at }) =>
      ({
        cells: [
          { value: title }, // Title
          { value: dayjs(created_at).format("MM-DD-YYYY"), size: "small" }, // Create date
          { value: body }, // Message
          { value: id, size: "tiny" }, // ID
        ],
        id,
      }) as UITypes.TableBodyRow,
  )
}

export const convertWarehousesToTableRows = (data?: ApiTypes.Model.Warehouse[]): UITypes.TableBodyRow[] => {
  if (!data?.length) return []

  return data.map(
    ({ id, name, address, phone_number, zip, state, devices_qty, managers }) =>
      ({
        cells: [
          {
            value: name,
          }, // Warehouse
          {
            value: devices_qty,
            size: "small",
          }, // Devices Qty
          {
            value: address,
          }, // Address
          {
            value: zip?.code,
            size: "small",
          }, // Zip code
          {
            value: state?.name,
            size: "small",
          }, // State
          {
            value: phone_number,
          }, // Phone
          {
            value: managers,
          }, // Manager
        ],
        id,
      }) as UITypes.TableBodyRow,
  )
}

export const convertDevicesToTableRows = (data?: ApiTypes.Model.Device[]): UITypes.TableBodyRow[] => {
  if (!data?.length) return []

  return data.map(
    ({
      id,
      sn,
      homeowner_name,
      city,
      zip,
      state,
      membership,
      accessories,
      residence_type,
      hvac,
      system_type,
      activation_date,
    }) =>
      ({
        cells: [
          { type: "checkbox" }, // Checkbox,
          {
            value: sn,
            size: "small",
          }, // Device SN
          {
            value: homeowner_name,
            // size:"small"
          }, // Customer name
          {
            value: city?.name,
            size: "small",
          }, // City
          {
            value: zip?.code,
            size: "small",
          }, // Zip code
          {
            value: state?.name,
            size: "small",
          }, // State
          {
            value: activation_date,
          }, // Activation date
          {
            value: membership,
            size: "small",
          }, // Membership
          {
            value: residence_type?.name,
            size: "middle",
          }, // Residence type
          {
            value: system_type,
            size: "small",
          }, // System type
          {
            value: accessories,
            size: "small",
          }, // Accessories
          {
            value: hvac,
            size: "small",
          }, // System age
        ],
        id,
      }) as UITypes.TableBodyRow,
  )
}

export const convertDevicesToDashboardTableRows = (data?: ApiTypes.Model.Device[]): UITypes.TableBodyRow[] => {
  if (!data?.length) return []

  return data.map(
    ({
      id,
      sn,
      homeowner_name,
      city,
      state,
      zip,
      address,
      membership,
      alerts,
      accessories,
      system_age,
      system_type,
      activation_date,
      phisical_status,
      work_status,
      hvac,
    }) =>
      ({
        cells: [
          { type: "checkbox" }, // Checkbox,
          {
            value: sn,
            size: "small",
          }, // Device S/N
          {
            value: phisical_status,
            size: "small",
            type: "devices-physical-status",
          }, // Status
          {
            // title: "Work Status",
            size: "small",
            value: work_status,
            type: "devices-work-status",
          },
          {
            value: homeowner_name,
            size: "middle",
          }, // Customer
          {
            value: zip?.code,
            size: "small",
          }, // Zip code
          {
            value: activation_date,
            size: "middle",
          }, // Activation date
          {
            value: `${address ? address : ""} ${state?.name ? state?.name : ""} ${city?.name ? city?.name : ""}`,
          }, // Address
          {
            value: hvac,
            size: "small",
          }, // hvac
          {
            value: alerts,
            size: "small",
          }, // Alerts
          {
            value: membership,
            size: "small",
          }, // Membership
          {
            value: system_type,
            size: "small",
          }, // System type
          {
            value: accessories,
            size: "small",
          }, // Accessories
        ],
        id,
      }) as UITypes.TableBodyRow,
  )
}

export const convertMessagesHistoryToTableRows = (data?: ApiTypes.Model.MessageHitory[]): UITypes.TableBodyRow[] => {
  if (!data?.length) return []

  return data.map(
    ({ id, title, body, pending, failed, delivered, date }) =>
      ({
        cells: [
          {
            value: title,
          }, // Title
          {
            value: body,
          }, // Message
          {
            value: date,
          }, // Date
          {
            value: delivered,
            size: "small",
            color: "blue",
          }, // Delivered
          {
            value: pending,
            size: "small",
            color: "orange",
          }, // Pending
          {
            value: failed,
            size: "small",
            color: "red",
          }, // Failed
        ],
        id,
      }) as UITypes.TableBodyRow,
  )
}

export const convertWHManagerOrdersToTableRows = (data?: ApiTypes.Model.WHManagerOrder[]): UITypes.TableBodyRow[] => {
  if (!data?.length) return []

  return data.map(
    ({ id, contractor_id, order_quantity, status, company_name, brand_name }) =>
      ({
        cells: [
          {
            value: id,
            size: "middle",
          }, // Order number
          {
            value: status,
            size: "middle",
            type: "wh-order-status",
          }, // Status
          {
            value: company_name,
          }, // Company name
          {
            value: brand_name,
          }, // Brand name
          {
            value: order_quantity,
            size: "middle",
          }, // Order quantity
          {
            value: contractor_id,
            size: "middle",
          }, // Contractor ID
        ],
        status,
        id,
      }) as UITypes.TableBodyRow,
  )
}

export const convertWHManagerDevicessToTableRows = (
  data?: ApiTypes.Model.WHManagerDevice[],
): UITypes.TableBodyRow[] => {
  if (!data?.length) return []

  return data.map(
    ({ id, sn, input_date, phisical_statue }) =>
      ({
        cells: [
          { type: "checkbox" }, // Checkbox,
          {
            value: sn,
            size: "large",
          }, // Device S/N
          {
            value: input_date,
            size: "large",
          }, // Date of Input
          {
            value: phisical_statue,
            size: "large",
            type: "devices-physical-status",
          }, // Status
        ],
        id,
      }) as UITypes.TableBodyRow,
  )
}

export const convertMessageReportToTableRows = (data?: ApiTypes.Model.MessageReport[]): UITypes.TableBodyRow[] => {
  if (!data?.length) return []

  return data.map(
    ({
      id,
      device_sn,
      device_id,
      filter_history_id,
      client_name,
      city,
      zip_code,
      state,
      activation_date,
      membership,
      residence_type,
      installation_type,
      accessories,
      status,
      system_age,
      system_type,
      message_template_id,
    }) =>
      ({
        cells: [
          {
            // title: "Device S/N",
            size: "small",
            value: device_sn,
          },
          {
            // title: "Customer name",
            size: "middle",
            value: client_name,
          },
          {
            // title: "City",
            size: "small",
            value: city,
          },
          {
            // title: "Zip code",
            size: "small",
            value: zip_code,
          },
          {
            // title: "State",
            size: "small",
            value: state,
          },
          {
            // title: "Activation date",
            size: "middle",
            value: activation_date,
          },
          {
            // title: "Membership",
            size: "small",
            value: membership,
          }, // Membership
          {
            // title: "Residence type",
            size: "middle",
            value: residence_type,
          },
          {
            // title: "System type",
            size: "small",
            value: system_type,
          },
          {
            // title: "Accessories",
            size: "small",
            value: accessories,
          },
          {
            // title: "HVAC",
            size: "small",
            value: installation_type,
          },
          {
            // title: "Status",
            size: "small",
            value: status,
            type: "message-report-status",
          },
        ],
        id,
      }) as UITypes.TableBodyRow,
  )
}

export const convertUsersToTableRows = (data?: ApiTypes.Model.User[]): UITypes.TableBodyRow[] => {
  if (!data?.length) return []

  return data.map(
    ({ id, email, full_name, phone, role, created_by, creation_date }) =>
      ({
        cells: [
          {
            // title: "Name",
            value: full_name,
            size: "middle",
          },
          {
            // title: "Role",
            value: transformString(role?.type),
            size: "middle",
          },
          {
            // title: "Email",
            value: email,
          },
          {
            // title: "Phone",
            value: phone,
          },
          {
            // title: "Creation date",
            value: creation_date,
          },
          {
            // title: "Created By",
            value: created_by,
          },
        ],
        id,
      }) as UITypes.TableBodyRow,
  )
}

export const convertValidatedDevicesToState = (data: ApiTypes.Model.InvalideDevice[]): UITypes.InvalidDevice[] => {
  if (!data?.length) return []

  return data.map((item, index) => ({
    sn: item?.sn ? item.sn : "",
    row: item?.row ? item.row : (index += 1),
    uid: item?.uid ? item.uid : uuidv4(),
    errorMessage: item?.error_message ? item?.error_message : "",
  }))
}

export const convertVendorDevicesToTableRows = (
  data?: ApiTypes.Model.VendorDevice[],
  callback?: (id: number) => void,
): UITypes.TableBodyRow[] => {
  if (!data?.length) return []

  return data.map(
    ({
      id,
      sn,
      work_status,
      phisical_status,
      contractor,
      contractor_name,
      activation_date,
      red_flag,
      red_flag_type,
      homeowner,
      address,
      email,
      phone,
      installation_type,
      device_location,
    }) =>
      ({
        cells: [
          {
            type: "checkbox",
            size: "tiny",
          },
          {
            // title: "S/N",
            size: "middle",
            value: sn,
            callback: () => callback && callback(id),
          },
          {
            // title: "Work Status",
            size: "small",
            value: work_status,
            type: "devices-work-status",
          },
          {
            // title: "Physical status",
            size: "small",
            value: phisical_status,
            type: "devices-physical-status",
          },
          {
            // title: "Contractor name",
            size: "middle",
            value: contractor_name,
          },
          {
            // title: "Activation date",
            size: "middle",
            value: activation_date,
          },
          {
            // title: "Red Flag",
            size: "small",
            value: `${red_flag ? "Yes" : "No"}`,
          },
          {
            // title: "Red Flag Type",
            size: "middle",
            value: red_flag_type,
          },
          {
            // title: "Customer",
            size: "middle",
            value: homeowner,
          },
          {
            // title: "Address",
            value: address,
          },
          {
            // title: "Email",
            size: "middle",
            value: email,
          },
          {
            // title: "Phone",
            size: "middle",
            value: phone,
          },
          {
            // title: "hvac",
            size: "small",
            value: installation_type,
          },
          {
            // title: "Device Location",
            value: device_location,
          },
        ],
        id: Number(id),
        isRedFlag: red_flag,
      }) as UITypes.TableBodyRow,
  )
}

export const convertDeviceHistoryLog = (data: ApiTypes.Model.HistoryLog[]): UITypes.HistoryLog[] => {
  if (!data?.length) return []

  return data?.map((item) => ({
    status: item?.status,
    author: item?.author,
    lastUpdate: dayjs(item?.last_update).format("MM/DD/YYYY HH:mm"),
  }))
}

export const convertEditDevicesToState = (data: ApiTypes.Model.Device[]): UITypes.Device[] => {
  if (!data?.length) return []

  const checkInstallationType = (value) => {
    switch (value) {
      case "new":
        return [
          {
            value: "new",
            label: "New",
          },
        ]
      case "existing":
        return [
          {
            value: "existing",
            label: "Replacement",
          },
        ]
      default:
        return []
    }
  }

  return data?.map((item) => {
    return {
      _id: uuidv4(),
      id: item?.id ? item?.id : undefined,
      serialNumber: item?.sn ? item?.sn : "",
      address1: item?.address1 ? item?.address1 : "",
      address2: item?.address2 ? item?.address2 : "",
      zip: convertDataToOptions({ data: [item?.zip] }, "id", "code"),
      state: convertDataToOptions({ data: [item?.state] }, "id", "name"),
      city: convertDataToOptions({ data: [item?.city] }, "id", "name"),
      residentialType: convertDataToOptions({ data: [item?.residence_type] }, "id", "name"),
      deviceLocation: convertDataToOptions({ data: [item?.location] }, "id", "name"),
      deviceName: item?.name ? item?.name : "",
      membership: item?.membership ? item?.membership : "",
      installationType: checkInstallationType(item.installation_type),
    }
  })
}
