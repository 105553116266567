import styles from "../../../assets/scss/index.module.scss"
import { useEffect, useMemo, useState } from "react"
import { ContentWrapper } from "../../../components/common/ContentWrapper"
import { Table } from "../../../components/table/Table"
import { Pagination } from "../../../components/common/Pagination"
import { ButtonNew } from "../../../components/form/ButtonNew"
import { CheckboxNew } from "../../../components/form/CheckboxNew"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import { convertTechniciansToTableRows } from "../../../utils/convertData"
import { toast } from "react-toastify"
import { showAddTechnicianModal, showEditTechnicianModal } from "../../../store/modalSlise"
import { setEditableTechnician, setRequestParams } from "../../../store/tempDataSlise"
import { UITypes } from "../../../types"
import { useLazyGetTechniciansQuery } from "../../../store/api/techniciansApi"
import { FilterTags } from "../../../components/common/FilterTags"

// TABLE HEADER
const tableHeaderCells: UITypes.TableHeaderCell[] = [
  {
    title: "Technician (ID number)",
    type: "sort-search",
    name: "id",
  },
  {
    title: "Full Name",
    type: "sort-search",
    name: "full_name",
    size: "large",
  },
  {
    title: "Username",
    type: "sort-search",
    name: "username",
  },
  {
    title: "Installed Devices",
    type: "sort-search",
    name: "installed_devices",
  },
]

export const Technicians = () => {
  const dispatch = useAppDispatch()
  const [triggerGetTechnicians, { isFetching, data }] = useLazyGetTechniciansQuery()
  const requestParams = useAppSelector((state) => state.tempData.requestParams)
  const [isFirstRender, setFirstRender] = useState(true)

  const onSetEditableTechnician = (id?: number) => {
    if (!id || !data?.data?.length) {
      dispatch(setEditableTechnician(undefined))
    } else {
      const selectedTechnician = data?.data?.filter((item) => item.id === id)[0]
      dispatch(setEditableTechnician(selectedTechnician))
    }
  }

  const onEditClick = (id: number) => {
    onSetEditableTechnician(id)
    dispatch(showEditTechnicianModal(true))
  }

  const onAddClick = () => {
    dispatch(showAddTechnicianModal(true))
  }

  const onShowToggleDisabled = () => {
    dispatch(
      setRequestParams({
        isEnabled: {
          value: requestParams?.isEnabled?.value === 0 ? undefined : 0,
          label: "Show Disabled",
          type: "checkbox",
        },
      }),
    )
  }

  const sendRequest = async (params: UITypes.Params) => {
    try {
      const res: any = await triggerGetTechnicians(params)
      if (res?.error) {
        toast.error(res?.error?.data?.message)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (isFirstRender) {
      setFirstRender(false)
      return
    }
    sendRequest(requestParams === null ? {} : requestParams)
  }, [requestParams, isFirstRender])

  // componentWillUnmount hook
  useEffect(
    () => () => {
      dispatch(setRequestParams(null))
    },
    [],
  )

  return (
    <>
      <ContentWrapper title="Technicians">
        <nav className={styles.tableNavWrapper}>
          <div>
            <ButtonNew onClick={onAddClick} color="blue">
              Add technician
            </ButtonNew>
          </div>
          <div className={styles.tableNavButtonsWrapper}>
            <CheckboxNew
              className={styles.tableNavItem}
              isReverse={true}
              id="fulfilled"
              onChange={onShowToggleDisabled}
            >
              Show Disabled
            </CheckboxNew>
            {/* <ButtonNew className={styles.tableNavItem}>Export</ButtonNew> */}
          </div>
        </nav>
        <FilterTags />
        <Table
          headerCells={tableHeaderCells}
          bodyRows={convertTechniciansToTableRows(data?.data)}
          isLoading={isFetching}
          onEditClick={onEditClick}
        />
        <Pagination meta={data?.meta} />
      </ContentWrapper>
    </>
  )
}
